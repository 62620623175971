import {
  CircleLayerSpecification,
  FillLayerSpecification,
  LayerSpecification,
  LineLayerSpecification,
  Map,
  SymbolLayerSpecification,
  VectorSourceSpecification,
} from 'mapbox-gl';
import { Consts } from '../global/consts';

export const ID = 'cadernos_drenagem.plano_de_acoes_1a_ed_2022';
const ZOOM_THRESHOLD = 14.5;

namespace sources {
  export const POLYGON_ID = 'pdd_1ed-source';
  export const CENTROID_ID = 'pdd_1ed-centroid-layer';
  export const POLYGON_VECTORSOURCELAYER = 'cadernos_drenagem.v_plano_de_acoes_1a_ed_2022_2023_11_09_union';
  export const CENTROID_VECTORSOURCELAYER = 'cadernos_drenagem.v_plano_de_acoes_1a_ed_2022_2023_11_09_centroid';

  export const POLYGON = {
    type: 'vector',
    tiles: [`${Consts.VECTOR_TILES_SERVER_URL}/${POLYGON_VECTORSOURCELAYER}/{z}/{x}/{y}.pbf`],
    promoteId: 'id',
  } as VectorSourceSpecification;

  export const CENTROID = {
    type: 'vector',
    tiles: [`${Consts.VECTOR_TILES_SERVER_URL}/${sources.CENTROID_VECTORSOURCELAYER}/{z}/{x}/{y}.pbf`],
    promoteId: 'id',
  } as VectorSourceSpecification;
}

namespace colors {
  export const HOVER = '#3B82F6';
  export const DEFAULT = '#508563';
}

namespace layers {
  export const PERTO_FILL = {
    'id': 'pdd_1ed-perto_fill-layer',
    'source': sources.POLYGON_ID,
    'source-layer': sources.POLYGON_VECTORSOURCELAYER,
    'minzoom': ZOOM_THRESHOLD,
    'maxzoom': 22,
    'type': 'fill',
    'paint': {
      'fill-color': 'white',
      'fill-opacity': 1,
    },
    'layout': {
      visibility: 'none',
    },
  } as FillLayerSpecification;

  export const PERTO_LINE = {
    'id': 'pdd_1ed-perto_line-layer',
    'source': sources.POLYGON_ID,
    'source-layer': sources.POLYGON_VECTORSOURCELAYER,
    'minzoom': ZOOM_THRESHOLD,
    'maxzoom': 22,
    'type': 'line',
    'paint': {
      'line-color': [
        'case',
        ['boolean', ['feature-state', 'active'], false],
        'transparent',
        ['boolean', ['feature-state', 'hover'], false],
        colors.HOVER,
        colors.DEFAULT,
      ],
      'line-width': 3,
    },
    'layout': {
      visibility: 'none',
    },
  } as LineLayerSpecification;

  export const LONGE_CIRCLE = {
    'id': 'pdd_1ed-longe-layer',
    'source': sources.CENTROID_ID,
    'source-layer': sources.CENTROID_VECTORSOURCELAYER,
    'type': 'circle',
    'minzoom': 1,
    'maxzoom': ZOOM_THRESHOLD,
    'layout': {
      visibility: 'none',
    },
    'paint': {
      'circle-radius': 5,
      'circle-color': 'white',
      'circle-stroke-color': [
        'case',
        ['boolean', ['feature-state', 'active'], false],
        'transparent',
        ['boolean', ['feature-state', 'hover'], false],
        colors.HOVER,
        colors.DEFAULT,
      ],
      'circle-stroke-width': 3,
    },
  } as CircleLayerSpecification;

  export const LABEL = {
    'id': 'label_pdd_1ed-layer',
    'source': sources.CENTROID_ID,
    'source-layer': sources.CENTROID_VECTORSOURCELAYER,
    'type': 'symbol',
    'layout': {
      'text-font': ['Ubuntu Bold'],
      'text-field': ['concat', 'PLano de Ações - 1ed\n', ['get', 'obra']],
      'text-size': 11,
      'text-letter-spacing': 0.05,
      'text-anchor': 'bottom',
      'text-offset': [0, -0.8],
      'visibility': 'none',
      'text-allow-overlap': false,
    },
    'paint': {
      'text-color': [
        'case',
        ['boolean', ['feature-state', 'active'], false],
        'transparent',
        ['boolean', ['feature-state', 'hover'], false],
        colors.HOVER,
        colors.DEFAULT,
      ],
      'text-halo-width': [
        'case',
        ['boolean', ['feature-state', 'active'], false],
        0,
        ['boolean', ['feature-state', 'hover'], false],
        3,
        3,
      ],
      'text-halo-color': [
        'case',
        ['boolean', ['feature-state', 'active'], false],
        'transparent',
        ['boolean', ['feature-state', 'hover'], false],
        'white',
        'white',
      ],
    },
  } as SymbolLayerSpecification;

  export const GAMBI = {
    // Gambiarra estranha: com o layer abaixo, o hover do label funciona. Sem,
    // o hover do label não funciona. Repare que o layer abaixo não tem nem cor
    // nem circle-radius. Mas, se for removido, o hover do label não funciona.
    'id': 'gambi_pdd_1ed-layer',
    'source': sources.CENTROID_ID,
    'source-layer': sources.CENTROID_VECTORSOURCELAYER,
    'type': 'circle',
    'layout': {
      visibility: 'none',
    },
    'paint': {
      'circle-radius': 0,
    },
  } as CircleLayerSpecification;
}

export function Add(map: Map): { id: string; layersIds: string[] } {
  map.addSource(sources.POLYGON_ID, sources.POLYGON);
  map.addSource(sources.CENTROID_ID, sources.CENTROID);

  map.addLayer(layers.LONGE_CIRCLE);
  map.addLayer(layers.PERTO_FILL);
  map.addLayer(layers.PERTO_LINE);
  map.addLayer(layers.LABEL);
  map.addLayer(layers.GAMBI);

  return {
    id: ID,
    layersIds: [layers.LONGE_CIRCLE.id, layers.LABEL.id, layers.PERTO_LINE.id, layers.PERTO_FILL.id, layers.GAMBI.id],
  };
}

export const BBOX_FEATURE_URL = (() => {
  const url = new URL(
    `${Consts.GEOJSON_FEATURES_SERVER_URL}/collections/cadernos_drenagem.v_plano_de_acoes_1a_ed_2022_2023_11_09_bbox/items.json`,
  );
  url.searchParams.set('limit', '400');
  return url.toString();
})();

const INTERACTIVE_LAYERS_IDS: string[] = [layers.LABEL.id, layers.LONGE_CIRCLE.id, layers.PERTO_FILL.id];
const HIGHLIGHT_ON_HOVER_LAYERS: LayerSpecification[] = [layers.PERTO_LINE, layers.LONGE_CIRCLE, layers.LABEL];
const HIGHLIGHT_ON_CLICK_LAYERS: LayerSpecification[] = [layers.PERTO_LINE, layers.LONGE_CIRCLE, layers.LABEL];

export { HIGHLIGHT_ON_CLICK_LAYERS, HIGHLIGHT_ON_HOVER_LAYERS, INTERACTIVE_LAYERS_IDS };
