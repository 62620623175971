import { GeoJSONFeature, Layer, MapMouseEvent } from 'mapbox-gl';
import { Libs } from '../libs/_libs';
import * as FeatureState from './feature-state';

/**
 * ===============================================================================
 *                              NAMESPACE CONTROLLERS
 * ===============================================================================
 */

namespace Controllers {
  export function OnMouseMove(interactiveLayersIds: string[], highlightOnHoverLayers: Layer[]): Function {
    return (e: MapMouseEvent & { features: GeoJSONFeature[] }) => {
      const feature = e.features[0];
      if (!interactiveLayersIds.includes(feature.layer.id)) return;
      const featureState = 'hover';
      FeatureState.Controllers.Set(feature.id as string, highlightOnHoverLayers, featureState);
      Libs.Mapbox.Map.getCanvas().style.cursor = 'pointer';
    };
  }

  export function OnMouseLeave(): Function {
    return () => {
      Libs.Mapbox.Map.getCanvas().style.cursor = '';
      FeatureState.Controllers.Clear('hover');
    };
  }
}

export { Controllers };
