import {
  CircleLayerSpecification,
  FillLayerSpecification,
  LayerSpecification,
  LineLayerSpecification,
  Map,
  SymbolLayerSpecification,
  VectorSourceSpecification,
} from 'mapbox-gl';
import { Consts } from '../global/consts';

export const ID = 'cadernos_drenagem-programado.limites';

namespace sources {
  export const POLYGON_ID = 'cadernos_drenagem_limites_programado-source';
  export const CENTROID_ID = 'label-cadernos_drenagem_limites_programado-source';
  export const POLYGON_VECTORSOURCELAYER = 'cadernos_drenagem.limites_2023_10_24';
  export const CENTROID_VECTORSOURCELAYER = 'cadernos_drenagem.v_limites_centroid_2023_10_24';

  export const POLYGON = {
    type: 'vector',
    tiles: [`${Consts.VECTOR_TILES_SERVER_URL}/${POLYGON_VECTORSOURCELAYER}/{z}/{x}/{y}.pbf?filter=prog='Programado'`],
    promoteId: 'id',
  } as VectorSourceSpecification;

  export const CENTROID = {
    type: 'vector',
    tiles: [`${Consts.VECTOR_TILES_SERVER_URL}/${CENTROID_VECTORSOURCELAYER}/{z}/{x}/{y}.pbf?filter=prog='Programado'`],
    promoteId: 'id',
  } as VectorSourceSpecification;
}

namespace colors {
  export const HOVER = '#3B82F6';
  export const OUTLINE = '#6A8E94';
  export const LINE = 'white';
}

namespace layers {
  export const FILL = {
    'id': 'cadernos_drenagem_limites_programado_fill-layer',
    'source': sources.POLYGON_ID,
    'source-layer': sources.POLYGON_VECTORSOURCELAYER,
    'type': 'fill',
    'paint': {
      'fill-color': [
        'case',
        ['boolean', ['feature-state', 'active'], false],
        'transparent',
        ['boolean', ['feature-state', 'hover'], false],
        colors.HOVER,
        colors.OUTLINE,
      ],
      'fill-opacity': 0.1,
    },
    'layout': {
      visibility: 'none',
    },
  } as FillLayerSpecification;

  export const OUTLINE = {
    'id': 'cadernos_drenagem_limites_programado_outline-layer',
    'source': sources.POLYGON_ID,
    'source-layer': sources.POLYGON_VECTORSOURCELAYER,
    'type': 'line',
    'paint': {
      'line-color': [
        'case',
        ['boolean', ['feature-state', 'active'], false],
        'transparent',
        ['boolean', ['feature-state', 'hover'], false],
        colors.HOVER,
        colors.OUTLINE,
      ],
      'line-width': 5,
    },
    'layout': {
      visibility: 'none',
    },
  } as LineLayerSpecification;

  export const LINE = {
    'id': 'cadernos_drenagem_limites_programado-layer',
    'source': sources.POLYGON_ID,
    'source-layer': sources.POLYGON_VECTORSOURCELAYER,
    'type': 'line',
    'paint': {
      'line-color': colors.LINE,
      'line-width': 1,
    },
    'layout': {
      visibility: 'none',
    },
  } as LineLayerSpecification;

  export const LABEL = {
    'id': 'label_cadernos_drenagem_limites_programado-layer',
    'source': sources.CENTROID_ID,
    'source-layer': sources.CENTROID_VECTORSOURCELAYER,
    'type': 'symbol',
    'layout': {
      'text-font': ['Ubuntu Regular'],
      'text-field': ['concat', 'Caderno\n', ['get', 'bacia_cade']],
      'text-max-width': 20,
      'text-size': [
        'interpolate',
        ['linear'],
        ['zoom'],
        // zoom 10 (ou menor):
        10,
        12,
        // zoom 13 (ou maior):
        13,
        16,
      ],
      'text-anchor': 'center',
      'text-transform': 'uppercase',
      'visibility': 'none',
    },
    'paint': {
      'text-color': [
        'case',
        ['boolean', ['feature-state', 'active'], false],
        'black',
        ['boolean', ['feature-state', 'hover'], false],
        colors.HOVER,
        colors.OUTLINE,
      ],
      'text-halo-width': 3,
      'text-halo-color': 'white',
    },
  } as SymbolLayerSpecification;

  export const GAMBI = {
    // Gambiarra estranha: com o layer abaixo, o hover do label funciona. Sem,
    // o hover do label não funciona. Repare que o layer abaixo não tem nem cor
    // nem circle-radius. Mas, se for removido, o hover do label não funciona.
    'id': 'gambi_cadernos_drenagem_limites_programado-layer',
    'source': sources.CENTROID_ID,
    'source-layer': sources.CENTROID_VECTORSOURCELAYER,
    'type': 'circle',
    'layout': {
      visibility: 'none',
    },
    'paint': {
      'circle-radius': 0,
    },
  } as CircleLayerSpecification;
}

namespace active {
  export const OUTLINE = {
    ...layers.OUTLINE,
    id: 'cadernos_drenagem_limites_programado-layer-active',
    paint: {
      'line-color': [
        'case',
        ['boolean', ['feature-state', 'active'], false],
        'transparent',
        ['boolean', ['feature-state', 'hover'], false],
        colors.HOVER,
        'transparent',
      ],
      'line-width': 3,
    },
  } as LineLayerSpecification;
}

export function Add(map: Map): { id: string; layersIds: string[] } {
  map.addSource(sources.POLYGON_ID, sources.POLYGON);
  map.addSource(sources.CENTROID_ID, sources.CENTROID);

  map.addLayer(layers.FILL);
  map.addLayer(layers.OUTLINE);
  map.addLayer(layers.LINE);
  map.addLayer(layers.LABEL);
  map.addLayer(layers.GAMBI);
  map.addLayer(active.OUTLINE);

  return {
    id: ID,
    layersIds: [layers.FILL.id, layers.OUTLINE.id, layers.LINE.id, layers.LABEL.id, layers.GAMBI.id, active.OUTLINE.id],
  };
}

export const BBOX_FEATURE_URL = (() => {
  const url = new URL(
    `${Consts.GEOJSON_FEATURES_SERVER_URL}/collections/cadernos_drenagem.v_limites_bbox_2023_10_24/items.json`,
  );
  url.searchParams.set('limit', '400');
  url.searchParams.set('filter', `prog='Programado'`);
  return url.toString();
})();

const INTERACTIVE_LAYERS_IDS: string[] = [layers.LABEL.id];
const HIGHLIGHT_ON_HOVER_LAYERS: LayerSpecification[] = [layers.LABEL, layers.OUTLINE];
const HIGHLIGHT_ON_CLICK_LAYERS: LayerSpecification[] = [layers.LABEL, layers.OUTLINE, layers.FILL, active.OUTLINE];

export { HIGHLIGHT_ON_CLICK_LAYERS, HIGHLIGHT_ON_HOVER_LAYERS, INTERACTIVE_LAYERS_IDS };
