namespace Entities {
  const _layers = new Map();

  export function Clear() {
    _layers.clear();
  }

  export function Set(id: string, layersIds: string[]) {
    _layers.set(id, layersIds);
  }

  export function Get(id: string): string[] {
    return _layers.get(id);
  }
}

/**
 * ============================================================================
 *                                     EXPORTS
 * ============================================================================
 */

export { Entities };
