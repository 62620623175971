import { Map, CircleLayerSpecification, SymbolLayerSpecification, VectorSourceSpecification } from 'mapbox-gl';
import { Consts } from '../global/consts';

export const ID = 'meio_fisico_topografia.pontos_cotados';
export const MIN_ZOOM = 15;

namespace sources {
  export const POINT_ID = 'v_pontos_cotados';
  export const VECTORSOURCELAYER = 'meio_fisico_topografia.v_pontos_cotados';
  export const POINT = {
    type: 'vector',
    tiles: [`${Consts.VECTOR_TILES_SERVER_URL}/${VECTORSOURCELAYER}/{z}/{x}/{y}.pbf`],
  } as VectorSourceSpecification;
}

namespace layers {
  const POINT_ID = 'v_pontos_cotados-layer';
  const LABEL_ID = 'v_pontos_cotados-label-layer';
  export const POINT = {
    'id': POINT_ID,
    'source': sources.POINT_ID,
    'source-layer': sources.VECTORSOURCELAYER,
    'type': 'circle',
    'minzoom': MIN_ZOOM,
    'maxzoom': 22,
    'paint': {
      'circle-radius': 3,
      'circle-color': '#616161',
      'circle-stroke-color': 'white',
      'circle-stroke-width': 1,
    },
    'layout': {
      visibility: 'none',
    },
  } as CircleLayerSpecification;

  export const LABEL = {
    'id': LABEL_ID,
    'source': sources.POINT_ID,
    'source-layer': sources.VECTORSOURCELAYER,
    'type': 'symbol',
    'minzoom': MIN_ZOOM,
    'maxzoom': 22,
    'layout': {
      'text-font': ['Ubuntu Regular'],
      'text-field': ['get', 'altura'],
      'text-size': 9,
      'text-letter-spacing': 0.05,
      'text-anchor': 'bottom',
      'text-offset': [0, -0.5],
      'visibility': 'none',
    },
    'paint': {
      'text-color': '#616161',
      'text-halo-color': 'white',
      'text-halo-width': 1,
    },
  } as SymbolLayerSpecification;
}

export function Add(map: Map): { id: string; layersIds: string[] } {
  map.addSource(sources.POINT_ID, sources.POINT);
  map.addLayer(layers.POINT);
  map.addLayer(layers.LABEL);
  return { id: ID, layersIds: [layers.POINT.id, layers.LABEL.id] };
}
