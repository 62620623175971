import { Consts } from '../global/consts';

export const TERRORFETCHNAME = 'FetchError';

export class TErrorFetch extends Error {
  url: string;
  responseStatus: number;
  responseText: string;

  constructor(url: string, status: number, errorMessage: string, text: string) {
    super(errorMessage);

    //The problem is that Javascript's built-in class Error breaks the
    // prototype chain by switching the object to be constructed (i.e. this)
    // to a new, different object, when you call super and that new object
    // doesn't have the expected prototype chain, i.e. it's an instance
    // of Error not of CustomError.
    // This problem can be elegantly solved using 'new.target', which is
    // supported since Typescript 2.2.
    Object.setPrototypeOf(this, TErrorFetch.prototype);

    this.name = TERRORFETCHNAME;
    this.url = url;
    this.responseStatus = status;
    this.responseText = text;
  }
}

export async function FetchGeoJSON(
  url: string,
): Promise<{ geojson: GeoJSON.FeatureCollection | GeoJSON.Feature; error: Error }> {
  let text: string;
  let response: Response;
  try {
    response = await fetch(url, {
      cache: 'no-cache',
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
    });
    text = await response.text();
    if (!response.ok) throw new Error(text);
    const json = JSON.parse(text);
    return { geojson: json, error: null };
  } catch (error) {
    return { geojson: null, error: new TErrorFetch(url, response.status, error.message, text) };
  }
}

export async function FetchItem(
  url: string,
  field: string,
  id: string,
): Promise<{ geojson: GeoJSON.Feature; error: Error }> {
  // Wrapper para recuperar um "item" de uma "materializad view" do servidor de
  // features. A forma usual de recuperar, através de uma URL do tipo:
  // - <http://localhost:8181/pg_featureserv/collections/[MATERIALIZED_VIEW]/items/[ID].json
  // não funciona em view/materialized view pois não é possível criar um
  // primary key que sirva como índice. Segundo <https://github.com/CrunchyData/pg_featureserv/issues/86>
  // (que foi aberta em Oct 12, 2021 e ainda encontra-se com status "Open"):
  // - "The problem is that there is no concept of Primary Key on materialized views, and hence no metadata for it"
  // Não há previsão de PR para tomar "unique keys" como "ID column" pois:
  // - "But it's going to be a pretty hairy query against the metadata tables."
  // O resultado de "/items.json" é uma "GeoJSON.FeatureCollection". Quero retornar uma "GeoJSON.Feature" pois
  // é 1 rio/córrego/bacia etc.
  let text: string;
  let response: Response;
  let urlObj = new URL(url);
  urlObj.searchParams.set('limit', '1');
  urlObj.searchParams.set('filter', `${field}='${id}'`);
  try {
    response = await fetch(urlObj, {
      cache: 'no-cache',
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
    });
    text = await response.text();
    if (!response.ok) throw new Error(text);
    const json: GeoJSON.FeatureCollection = JSON.parse(text);
    // A instrução abaixo tranforma o "GeoJSON.FeatureCollection" em "GeoJSON.Feature".
    const geojson: GeoJSON.Feature = {
      type: 'Feature',
      geometry: { ...json.features[0].geometry },
      properties: { ...json.features[0].properties },
    };
    return { geojson, error: null };
  } catch (error) {
    return { geojson: null, error: new TErrorFetch(url.toString(), response.status, error.message, text) };
  }
}

export async function FetchCollection(url: URL): Promise<{ geojson: GeoJSON.FeatureCollection; error: Error }> {
  // Wrapper para recuperar uma "collection" servidor de features.
  // A forma usual de recuperar, através de uma URL do tipo:
  // - <http://localhost:8181/pg_featureserv/collections/[MATERIALIZED_VIEW]/items/[ID].json
  // não funciona em view/materialized view pois não é possível criar um
  // primary key que sirva como índice. Segundo <https://github.com/CrunchyData/pg_featureserv/issues/86>
  // (que foi aberta em Oct 12, 2021 e ainda encontra-se com status "Open"):
  // - "The problem is that there is no concept of Primary Key on materialized views, and hence no metadata for it"
  // Não há previsão de PR para tomar "unique keys" como "ID column" pois:
  // - "But it's going to be a pretty hairy query against the metadata tables."
  let text: string;
  let response: Response;
  try {
    response = await fetch(url, {
      cache: 'no-cache',
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
    });
    text = await response.text();
    if (!response.ok) throw new Error(text);
    const geojson: GeoJSON.FeatureCollection = JSON.parse(text);
    return { geojson: geojson, error: null };
  } catch (error) {
    return { geojson: null, error: new TErrorFetch(url.toString(), response.status, error.message, text) };
  }
}

export async function OnGetFeatureFromLatLng(functionName: string): Promise<Function> {
  // Retorna uma função que recupera um "feature" de uma "function" do servidor de
  // features, pg_featureserv. É bom para obter uma bacia ou caderno de drenagem
  // que contém um dado ponto (lat, lng). Veja em <READEME.md#Spatial Function do Pocketbase>
  return async function (lat: number, lng: number): Promise<{ geojson: GeoJSON.Feature; error: Error }> {
    let text: string;
    let response: Response;
    const url = new URL(`${Consts.GEOJSON_FEATURES_SERVER_URL}/functions/${functionName}/items.json`);
    url.searchParams.set('lat', lat.toString());
    url.searchParams.set('lng', lng.toString());
    try {
      response = await fetch(url, {
        cache: 'no-cache',
        method: 'GET',
        headers: {
          Accept: 'application/json',
        },
      });
      text = await response.text();
      if (!response.ok) throw new Error(text);
      const json: GeoJSON.FeatureCollection = JSON.parse(text);
      // A instrução abaixo tranforma o "GeoJSON.FeatureCollection" em "GeoJSON.Feature".
      const geojson: GeoJSON.Feature = {
        type: 'Feature',
        geometry: { ...json.features[0].geometry },
        properties: { ...json.features[0].properties },
      };
      return { geojson, error: null };
    } catch (error) {
      return { geojson: null, error: new TErrorFetch(url.toString(), response.status, error.message, text) };
    }
  };
}
