import {
  CircleLayerSpecification,
  LayerSpecification,
  Map,
  VectorSourceSpecification,
} from 'mapbox-gl';
import { Consts } from '../global/consts';
import { dates } from './pontos-alagamento-00-years-ago';

export const ID = 'alagamentos.v_lu_eventos_05_years_ago';

namespace sources {
  export const POINT_ID = 'v_lu_eventos_05_years_ago';
  export const POINT_VECTORSOURCELAYER = 'alagamentos.v_lu_eventos';
  export const POINT = {
    type: 'vector',
    tiles: [`${Consts.VECTOR_TILES_SERVER_URL}/${POINT_VECTORSOURCELAYER}/{z}/{x}/{y}.pbf`],
    promoteId: 'lu_id',
  } as VectorSourceSpecification;
}

namespace colors {
  export const HOVER = '#3B82F6';
  export const CIRCLE_FILL = '#FFAF8F';
}

namespace layers {
  export const CIRCLE = {
    'id': 'v_lu_eventos_05_years_ago-layer',
    'source': sources.POINT_ID,
    'source-layer': sources.POINT_VECTORSOURCELAYER,
    'type': 'circle',
    'filter': ['<=', ['get', 'pa_dia_max'], dates.FIVE_YEARS_AGO],
    'minzoom': 1,
    'maxzoom': 22,
    'paint': {
      'circle-radius': [
        'case',
        ['boolean', ['feature-state', 'active'], false],
        6,
        ['boolean', ['feature-state', 'hover'], false],
        6,
        3,
      ],
      'circle-color': [
        'case',
        ['boolean', ['feature-state', 'active'], false],
        'black',
        ['boolean', ['feature-state', 'hover'], false],
        colors.HOVER,
        colors.CIRCLE_FILL,
      ],
    },
    'layout': {
      visibility: 'none',
    },
  } as CircleLayerSpecification;
}

export function Add(map: Map): { id: string; layersIds: string[] } {
  map.addSource(sources.POINT_ID, sources.POINT);
  map.addLayer(layers.CIRCLE);
  return { id: ID, layersIds: [layers.CIRCLE.id] };
}

const INTERACTIVE_LAYERS_IDS: string[] = [layers.CIRCLE.id];
const HIGHLIGHT_ON_HOVER_LAYERS: LayerSpecification[] = [layers.CIRCLE];
const HIGHLIGHT_ON_CLICK_LAYERS: LayerSpecification[] = [layers.CIRCLE];

export { HIGHLIGHT_ON_CLICK_LAYERS, HIGHLIGHT_ON_HOVER_LAYERS, INTERACTIVE_LAYERS_IDS };
