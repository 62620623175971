import { Map } from 'mapbox-gl';
import * as ActiveFeature from '../domains/active-feature';
import * as HoverFeature from '../domains/hover-feature';
import * as LayerCollection from '../domains/layer-collection';
import * as LayerState from '../domains/layer-state';
import * as LayerVisibility from '../domains/layer-visibility';
import * as Index from './_index';

interface layerDefaultState {
  layer: any;
  default: boolean;
}

const LAYERS: layerDefaultState[] = [
  { layer: Index.Subprefeituras, default: true },
  { layer: Index.Rmsp, default: false },
  { layer: Index.BaciasHidrograficas, default: true },
  { layer: Index.Hidrografia, default: true },
  { layer: Index.CurvasNivel, default: false },
  { layer: Index.PontosCotados, default: false },
  { layer: Index.PontosAlagamento05Anos, default: false },
  { layer: Index.PontosAlagamento02Anos, default: false },
  { layer: Index.PontosAlagamento00Anos, default: false },
  { layer: Index.ManchasInundacaoTr100, default: false },
  { layer: Index.ManchasInundacaoTr025, default: false },
  { layer: Index.ManchasInundacaoTr005, default: false },
  { layer: Index.CadernosDrenagemLimitesEmElaboracao, default: false },
  { layer: Index.CadernosDrenagemLimitesProgramados, default: false },
  { layer: Index.CadernosDrenagemLimitesPublicados, default: false },
  { layer: Index.Alternativa3, default: false },
  { layer: Index.Alternativa2, default: false },
  { layer: Index.Alternativa1, default: false },
  { layer: Index.ObrasComplementares, default: false },
  { layer: Index.Pdd1Ed, default: false },
  { layer: Index.PostosTelemetricos, default: false },
  { layer: Index.ReservatoriosAmortecimentoExistentes, default: true },
  { layer: Index.GaleriaExecutadaBLeao, default: false },
  { layer: Index.GaleriaExecutadaBLobo, default: false },
  { layer: Index.GaleriaExecutadaCaixa, default: false },
  { layer: Index.GaleriaExecutadaNo, default: false },
  { layer: Index.GaleriaExecutadaPv, default: false },
  { layer: Index.GaleriaExecutadaRamal, default: false },
  { layer: Index.GaleriaExecutadaRede, default: false },
  { layer: Index.AreaRiscoHidrologico, default: false },
  { layer: Index.AreaRiscoHidrologicoInativo, default: false },
  { layer: Index.AreaRiscoGeologico, default: false },
  { layer: Index.AreaRiscoGeologicoInativo, default: false },
];

export function AddToMap(map: Map): void {
  LAYERS.map(item => {
    const ids: { id: string; layersIds: string[] } = item.layer.Add(map);
    LayerCollection.Entities.Set(ids.id, ids.layersIds);
  });
}

export function TurnDefaultLayersOn(map: Map): void {
  LAYERS.filter(item => item.default).map(item => {
    const layerIds: string[] = LayerCollection.Entities.Get(item.layer.ID);
    layerIds.map(layerId => map.setLayoutProperty(layerId, 'visibility', 'visible'));
    LayerVisibility.Publishers.Dispatch(item.layer.ID, 'visible');
    LayerState.Entities.Set(item.layer.ID, true);
  });
}

export function RestoreLayerStates(map: Map): void {
  const states = LayerState.Entities.GetAll();
  states.forEach((state, id) => {
    const layerIds: string[] = LayerCollection.Entities.Get(id);
    layerIds.map(layerId => map.setLayoutProperty(layerId, 'visibility', state ? 'visible' : 'none'));
  });
}

export function SetMapEvents(map: Map) {
  LAYERS.filter(item => item.layer['INTERACTIVE_LAYERS_IDS']).map(item => {
    item.layer['INTERACTIVE_LAYERS_IDS'].map((layer: string) =>
      map.on(
        'mousemove',
        layer,
        HoverFeature.Controllers.OnMouseMove(
          item.layer['INTERACTIVE_LAYERS_IDS'],
          item.layer['HIGHLIGHT_ON_HOVER_LAYERS'],
        ).bind(map),
      ),
    );
    item.layer['INTERACTIVE_LAYERS_IDS'].map((layer: string) =>
      map.on('mouseleave', layer, HoverFeature.Controllers.OnMouseLeave().bind(map)),
    );
    item.layer['INTERACTIVE_LAYERS_IDS'].map((layer: string) =>
      map.on(
        'click',
        layer,
        ActiveFeature.Controllers.OnMouseClick(
          item.layer['INTERACTIVE_LAYERS_IDS'],
          item.layer['HIGHLIGHT_ON_CLICK_LAYERS'],
        ).bind(map),
      ),
    );
    item.layer['INTERACTIVE_LAYERS_IDS'].map((layer: string) =>
      map.on(
        'touchstart',
        layer,
        ActiveFeature.Controllers.OnMouseClick(
          item.layer['INTERACTIVE_LAYERS_IDS'],
          item.layer['HIGHLIGHT_ON_CLICK_LAYERS'],
        ).bind(map),
      ),
    );
  });
}

export function WhoseIsThis(interactiveLayerId: string): string {
  const layerId = LAYERS.filter(item => item.layer['INTERACTIVE_LAYERS_IDS'])
    .map(item => item.layer)
    .find(layer => layer['INTERACTIVE_LAYERS_IDS'].includes(interactiveLayerId));
  return layerId ? layerId.ID : null;
}
