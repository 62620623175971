import {
  CircleLayerSpecification,
  LayerSpecification,
  LineLayerSpecification,
  Map,
  SymbolLayerSpecification,
  VectorSourceSpecification,
} from 'mapbox-gl';
import { Consts } from '../global/consts';

export const ID = 'cadernos_drenagem.obras_alternativa_2_2024_03_21';

namespace sources {
  export const PONTOS_ID = 'alternativa_2_pontos_2024_02_01';
  export const LINHAS_ID = 'alternativa_2_linhas_2024_02_01';
  export const POLIGONOS_ID = 'alternativa_2_poligonos_2024_02_01';
  export const CENTROID_ID = 'alternativa_2_centroid_2024_02_01';
  export const PONTOS_VECTORSOURCELAYER = 'cadernos_drenagem.obras_pontos_2024_03_21';
  export const LINHAS_VECTORSOURCELAYER = 'cadernos_drenagem.obras_linhas_2024_03_21';
  export const POLIGONOS_VECTORSOURCELAYER = 'cadernos_drenagem.obras_poligonos_2024_03_21';
  export const CENTROID_VECTORSOURCELAYER = 'cadernos_drenagem.v_obras_poligonos_centroid_2024_03_21';

  export const POINT = {
    type: 'vector',
    tiles: [`${Consts.VECTOR_TILES_SERVER_URL}/${PONTOS_VECTORSOURCELAYER}/{z}/{x}/{y}.pbf`],
    promoteId: 'codigo',
  } as VectorSourceSpecification;

  export const LINHAS = {
    type: 'vector',
    tiles: [`${Consts.VECTOR_TILES_SERVER_URL}/${sources.LINHAS_VECTORSOURCELAYER}/{z}/{x}/{y}.pbf`],
    promoteId: 'codigo',
  } as VectorSourceSpecification;

  export const POLIGONOS_PERTO = {
    type: 'vector',
    tiles: [`${Consts.VECTOR_TILES_SERVER_URL}/${sources.POLIGONOS_VECTORSOURCELAYER}/{z}/{x}/{y}.pbf`],
    promoteId: 'codigo',
  } as VectorSourceSpecification;

  export const POLIGONOS_LONGE = {
    type: 'vector',
    tiles: [`${Consts.VECTOR_TILES_SERVER_URL}/${sources.CENTROID_VECTORSOURCELAYER}/{z}/{x}/{y}.pbf`],
    promoteId: 'codigo',
  } as VectorSourceSpecification;
}

namespace colors {
  export const HOVER = '#3B82F6';
  export const DEFAULT = '#0D9488';
}

namespace layers {
  const ZOOM_POLIGONOS_THRESHOLD = 13;

  export const PONTOS = {
    'id': 'pontos_2024_02_01-alternativa_2-layer',
    'source': sources.PONTOS_ID,
    'source-layer': sources.PONTOS_VECTORSOURCELAYER,
    'type': 'circle',
    'filter': ['==', 'altern', 2],
    'paint': {
      'circle-radius': [
        'case',
        ['boolean', ['feature-state', 'active'], false],
        0,
        ['boolean', ['feature-state', 'hover'], false],
        10,
        9,
      ],
      'circle-color': 'white',
      'circle-stroke-color': [
        'case',
        ['boolean', ['feature-state', 'active'], false],
        'transparent',
        ['boolean', ['feature-state', 'hover'], false],
        colors.HOVER,
        colors.DEFAULT,
      ],
      'circle-stroke-width': 3,
    },
    'layout': {
      visibility: 'none',
    },
  } as CircleLayerSpecification;

  export const PONTOS_LABEL = {
    'id': 'label_pontos_2024_02_01-alternativa_2-layer',
    'source': sources.PONTOS_ID,
    'source-layer': sources.PONTOS_VECTORSOURCELAYER,
    'type': 'symbol',
    'filter': ['==', 'altern', 2],
    'layout': {
      'text-font': ['Ubuntu Bold'],
      'text-field': ['get', 'tipo'],
      'text-size': 11,
      'text-letter-spacing': 0.05,
      'text-anchor': 'bottom',
      'text-offset': [0, -0.5],
      'text-allow-overlap': false,
      'visibility': 'none',
    },
    'paint': {
      'text-color': [
        'case',
        ['boolean', ['feature-state', 'active'], false],
        'black',
        ['boolean', ['feature-state', 'hover'], false],
        colors.HOVER,
        colors.DEFAULT,
      ],
      'text-halo-color': 'white',
      'text-halo-width': 3,
    },
  } as SymbolLayerSpecification;

  export const LINHAS = {
    'id': 'linhas_2024_02_01-alternativa_2-layer',
    'source': sources.LINHAS_ID,
    'source-layer': sources.LINHAS_VECTORSOURCELAYER,
    'type': 'line',
    'filter': ['==', 'altern', 2],
    'paint': {
      'line-color': [
        'case',
        ['boolean', ['feature-state', 'active'], false],
        'transparent',
        ['boolean', ['feature-state', 'hover'], false],
        colors.HOVER,
        colors.DEFAULT,
      ],
      'line-width': 5,
    },
    'layout': {
      visibility: 'none',
    },
  } as LineLayerSpecification;

  export const LINHAS_LABEL = {
    'id': 'label_linhas_2024_02_01-alternativa_2-layer',
    'source': sources.LINHAS_ID,
    'source-layer': sources.LINHAS_VECTORSOURCELAYER,
    'type': 'symbol',
    'filter': ['==', 'altern', 2],
    'layout': {
      'symbol-placement': 'line',
      'text-font': ['Ubuntu Bold'],
      'text-field': ['get', 'tipo'],
      'text-size': 11,
      'text-letter-spacing': 0.05,
      'text-allow-overlap': false,
      'visibility': 'none',
    },
    'paint': {
      'text-color': [
        'case',
        ['boolean', ['feature-state', 'active'], false],
        'black',
        ['boolean', ['feature-state', 'hover'], false],
        colors.HOVER,
        colors.DEFAULT,
      ],
      'text-halo-color': 'white',
      'text-halo-width': 3,
    },
    'minzoom': 12,
  } as SymbolLayerSpecification;

  export const POLIGONOS_PERTO = {
    'id': 'poligonos_perto_2024_02_01-alternativa_2-layer',
    'source': sources.POLIGONOS_ID,
    'source-layer': sources.POLIGONOS_VECTORSOURCELAYER,
    'type': 'line',
    'filter': ['==', 'altern', 2],
    'minzoom': ZOOM_POLIGONOS_THRESHOLD,
    'paint': {
      'line-color': [
        'case',
        ['boolean', ['feature-state', 'active'], false],
        'transparent',
        ['boolean', ['feature-state', 'hover'], false],
        colors.HOVER,
        colors.DEFAULT,
      ],
      'line-offset': -3,
      'line-width': 3,
    },
    'layout': {
      visibility: 'none',
    },
  } as LineLayerSpecification;

  export const POLIGONOS_LONGE = {
    'id': 'poligonos_longe_2024_02_01-alternativa_2-layer',
    'source': sources.CENTROID_ID,
    'source-layer': sources.CENTROID_VECTORSOURCELAYER,
    'type': 'circle',
    'filter': ['==', 'altern', 2],
    'minzoom': 0,
    'maxzoom': ZOOM_POLIGONOS_THRESHOLD,
    'paint': {
      'circle-radius': [
        'case',
        ['boolean', ['feature-state', 'active'], false],
        0,
        ['boolean', ['feature-state', 'hover'], false],
        10,
        9,
      ],
      'circle-color': 'white',
      'circle-stroke-color': [
        'case',
        ['boolean', ['feature-state', 'active'], false],
        'transparent',
        ['boolean', ['feature-state', 'hover'], false],
        colors.HOVER,
        colors.DEFAULT,
      ],
      'circle-stroke-width': 3,
    },
    'layout': {
      visibility: 'none',
    },
  } as CircleLayerSpecification;

  export const POLIGONOS_LABEL = {
    'id': 'label_poligonos_2024_02_01-alternativa_2-layer',
    'source': sources.CENTROID_ID,
    'source-layer': sources.CENTROID_VECTORSOURCELAYER,
    'type': 'symbol',
    'filter': ['==', 'altern', 2],
    'layout': {
      'text-font': ['Ubuntu Bold'],
      'text-field': ['get', 'tipo'],
      'text-size': 11,
      'text-letter-spacing': 0.05,
      'text-anchor': 'bottom',
      'text-offset': [0, -0.5],
      'text-allow-overlap': false,
      'visibility': 'none',
    },
    'paint': {
      'text-color': [
        'case',
        ['boolean', ['feature-state', 'active'], false],
        'black',
        ['boolean', ['feature-state', 'hover'], false],
        colors.HOVER,
        colors.DEFAULT,
      ],
      'text-halo-color': 'white',
      'text-halo-width': 3,
    },
  } as SymbolLayerSpecification;

  export const GAMBI = {
    // Gambiarra estranha: com o layer abaixo, o hover do label funciona. Sem,
    // o hover do label não funciona. Repare que o layer abaixo não tem nem
    // circle-radius. Mas, se for removido, o hover do label não funciona.
    'id': 'gambi_alternativa_2-layer',
    'source': sources.CENTROID_ID,
    'source-layer': sources.CENTROID_VECTORSOURCELAYER,
    'type': 'circle',
    'filter': ['==', 'altern', 2],
    'layout': {
      visibility: 'none',
    },
    'paint': {
      'circle-radius': 0,
    },
  } as CircleLayerSpecification;
}

export function Add(map: Map): { id: string; layersIds: string[] } {
  map.addSource(sources.PONTOS_ID, sources.POINT);
  map.addSource(sources.LINHAS_ID, sources.LINHAS);
  map.addSource(sources.POLIGONOS_ID, sources.POLIGONOS_PERTO);
  map.addSource(sources.CENTROID_ID, sources.POLIGONOS_LONGE);

  const LAYERS_TO_ADD = [
    layers.LINHAS,
    layers.POLIGONOS_LONGE,
    layers.POLIGONOS_PERTO,
    layers.PONTOS,
    layers.PONTOS_LABEL,
    layers.LINHAS_LABEL,
    layers.POLIGONOS_LABEL,
    layers.GAMBI,
  ];
  LAYERS_TO_ADD.map(layer => map.addLayer(layer));

  return {
    id: ID,
    layersIds: LAYERS_TO_ADD.map(layer => layer.id),
  };
}

const INTERACTIVE_LAYERS_IDS: string[] = [
  layers.PONTOS.id,
  layers.LINHAS.id,
  layers.POLIGONOS_PERTO.id,
  layers.POLIGONOS_LONGE.id,
  layers.PONTOS_LABEL.id,
  layers.LINHAS_LABEL.id,
  layers.POLIGONOS_LABEL.id,
];
const HIGHLIGHT_ON_HOVER_LAYERS: LayerSpecification[] = [
  layers.PONTOS,
  layers.LINHAS,
  layers.POLIGONOS_PERTO,
  layers.POLIGONOS_LONGE,
  layers.PONTOS_LABEL,
  layers.LINHAS_LABEL,
  layers.POLIGONOS_LABEL,
];
const HIGHLIGHT_ON_CLICK_LAYERS: LayerSpecification[] = [
  layers.PONTOS,
  layers.LINHAS,
  layers.POLIGONOS_PERTO,
  layers.POLIGONOS_LONGE,
  layers.PONTOS_LABEL,
  layers.LINHAS_LABEL,
  layers.POLIGONOS_LABEL,
];

export { HIGHLIGHT_ON_CLICK_LAYERS, HIGHLIGHT_ON_HOVER_LAYERS, INTERACTIVE_LAYERS_IDS };
