import {
  CircleLayerSpecification,
  LayerSpecification,
  LineLayerSpecification,
  Map,
  SymbolLayerSpecification,
  VectorSourceSpecification,
} from 'mapbox-gl';
import { Consts } from '../global/consts';

export const ID = 'infraestrutura_urbana.reservatorio_amortecimento';

namespace sources {
  export const POLYGON_ID = 'reservatorio_amortecimento_construido-source';
  export const CENTROID_ID = 'reservatorio_amortecimento_construido-centroid-source';
  export const POLYGON_VECTORSOURCELAYER = 'infraestrutura_urbana.v_reservatorio_amortecimento_2024_06_06_xing';
  export const CENTROID_VECTORSOURCELAYER = 'infraestrutura_urbana.v_reservatorio_amortecimento_2024_06_06_centroid';

  export const POLYGON = {
    type: 'vector',
    tiles: [`${Consts.VECTOR_TILES_SERVER_URL}/${POLYGON_VECTORSOURCELAYER}/{z}/{x}/{y}.pbf`],
    promoteId: 'rs_id',
  } as VectorSourceSpecification;

  export const CENTROID = {
    type: 'vector',
    tiles: [`${Consts.VECTOR_TILES_SERVER_URL}/${CENTROID_VECTORSOURCELAYER}/{z}/{x}/{y}.pbf`],
    promoteId: 'rs_id',
  } as VectorSourceSpecification;
}

namespace colors {
  export const HOVER = '#3B82F6';
  export const DEFAULT = '#617E8C';
}

namespace layers {
  const ZOOM_THRESHOLD = 16;

  export const LINE = {
    'id': 'reservatorio_amortecimento_construido-perto_line-layer',
    'source': sources.POLYGON_ID,
    'source-layer': sources.POLYGON_VECTORSOURCELAYER,
    'minzoom': ZOOM_THRESHOLD,
    'maxzoom': 22,
    'type': 'line',
    'paint': {
      'line-color': [
        'case',
        ['boolean', ['feature-state', 'active'], false],
        'transparent',
        ['boolean', ['feature-state', 'hover'], false],
        colors.HOVER,
        colors.DEFAULT,
      ],
      'line-width': 3,
    },
    'layout': {
      visibility: 'none',
    },
  } as LineLayerSpecification;

  export const CIRCLE = {
    'id': 'reservatorio_amortecimento_construido-longe-layer',
    'source': sources.CENTROID_ID,
    'source-layer': sources.CENTROID_VECTORSOURCELAYER,
    'type': 'circle',
    'minzoom': 1,
    'maxzoom': ZOOM_THRESHOLD,
    'layout': {
      visibility: 'none',
    },
    'paint': {
      'circle-radius': [
        'case',
        ['boolean', ['feature-state', 'active'], false],
        6,
        ['boolean', ['feature-state', 'hover'], false],
        6,
        5,
      ],
      'circle-color': 'white',
      'circle-stroke-color': [
        'case',
        ['boolean', ['feature-state', 'active'], false],
        'transparent',
        ['boolean', ['feature-state', 'hover'], false],
        colors.HOVER,
        colors.DEFAULT,
      ],
      'circle-stroke-width': 3,
    },
  } as CircleLayerSpecification;

  export const LABEL = {
    'id': 'reservatorio_amortecimento_construido-label',
    'source': sources.CENTROID_ID,
    'source-layer': sources.CENTROID_VECTORSOURCELAYER,
    'type': 'symbol',
    'layout': {
      'text-font': ['Ubuntu Bold'],
      'text-field': [
        'case',
        ['==', ['get', 'rs_tipo'], 'polder'],
        ['concat', 'Pôlder\n', ['get', 'rs_nome']],
        ['==', ['get', 'rs_tipo'], 'est. elevatória'],
        ['concat', 'Est. Elevatória\n', ['get', 'rs_nome']],
        ['concat', 'Reservatório\n', ['get', 'rs_nome']],
      ],
      'text-size': 11,
      'text-letter-spacing': 0.05,
      'text-anchor': 'bottom',
      'text-offset': [0, -0.8],
      'text-allow-overlap': false,
      'visibility': 'none',
    },
    'paint': {
      'text-color': [
        'case',
        ['boolean', ['feature-state', 'active'], false],
        'transparent',
        ['boolean', ['feature-state', 'hover'], false],
        colors.HOVER,
        colors.DEFAULT,
      ],
      'text-halo-color': [
        'case',
        ['boolean', ['feature-state', 'active'], false],
        'transparent',
        ['boolean', ['feature-state', 'hover'], false],
        'white',
        'white',
      ],
      'text-halo-width': 3,
    },
  } as SymbolLayerSpecification;

  export const GAMBI = {
    // Gambiarra estranha: com o layer abaixo, o hover do label funciona. Sem,
    // o hover do label não funciona. Repare que o layer abaixo não tem nem cor
    // nem circle-radius. Mas, se for removido, o hover do label não funciona.
    'id': 'gambi_reservatorio_amortecimento_construido-label',
    'source': sources.CENTROID_ID,
    'source-layer': sources.CENTROID_VECTORSOURCELAYER,
    'type': 'circle',
    'layout': {
      visibility: 'none',
    },
    'paint': {
      'circle-radius': 0,
    },
  } as CircleLayerSpecification;
}

export function Add(map: Map): { id: string; layersIds: string[] } {
  map.addSource(sources.POLYGON_ID, sources.POLYGON);
  map.addSource(sources.CENTROID_ID, sources.CENTROID);

  map.addLayer(layers.LINE);
  map.addLayer(layers.CIRCLE);
  map.addLayer(layers.LABEL);
  map.addLayer(layers.GAMBI);

  return {
    id: ID,
    layersIds: [layers.LINE.id, layers.CIRCLE.id, layers.LABEL.id, layers.GAMBI.id],
  };
}

export const BBOX_FEATURE_URL = (() => {
  const url = new URL(
    `${Consts.GEOJSON_FEATURES_SERVER_URL}/collections/infraestrutura_urbana.v_reservatorio_amortecimento_2024_06_06_bbox/items.json`,
  );
  url.searchParams.set('limit', '400');
  return url.toString();
})();

const INTERACTIVE_LAYERS_IDS: string[] = [layers.LABEL.id, layers.CIRCLE.id];
const HIGHLIGHT_ON_CLICK_LAYERS: LayerSpecification[] = [layers.CIRCLE, layers.LABEL, layers.LINE];
const HIGHLIGHT_ON_HOVER_LAYERS: LayerSpecification[] = [layers.CIRCLE, layers.LABEL, layers.LINE];

export { HIGHLIGHT_ON_CLICK_LAYERS, HIGHLIGHT_ON_HOVER_LAYERS, INTERACTIVE_LAYERS_IDS };
