import { CircleLayerSpecification, LayerSpecification, Map, VectorSourceSpecification } from 'mapbox-gl';
import { Consts } from '../global/consts';

export const ID = 'alagamentos.v_lu_eventos_00_years_ago';

export namespace dates {
  function getYYYMMDD(delta: number): string {
    const date = new Date();
    const year = date.getFullYear() + delta;
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }
  export const CURRENT_DATE = getYYYMMDD(0);
  export const TWO_YEARS_AGO = getYYYMMDD(-2);
  export const FIVE_YEARS_AGO = getYYYMMDD(-5);
}

namespace sources {
  export const POINT_ID = 'v_lu_eventos_00_years_ago';
  export const POINT_VECTORSOURCELAYER = 'alagamentos.v_lu_eventos';
  export const POINT = {
    type: 'vector',
    tiles: [`${Consts.VECTOR_TILES_SERVER_URL}/${POINT_VECTORSOURCELAYER}/{z}/{x}/{y}.pbf`],
    promoteId: 'lu_id',
  } as VectorSourceSpecification;
}

namespace colors {
  export const HOVER = '#3B82F6';
  export const CIRCLE_STROKE = '#FF5F1F';
  export const CIRCLE_FILL = 'white';
}

namespace layers {
  export const CIRCLE = {
    'id': 'v_lu_eventos_00_years_ago-layer',
    'source': sources.POINT_ID,
    'source-layer': sources.POINT_VECTORSOURCELAYER,
    'type': 'circle',
    'filter': [
      'all',
      ['<=', ['get', 'pa_dia_max'], dates.CURRENT_DATE],
      ['>', ['get', 'pa_dia_max'], dates.TWO_YEARS_AGO],
    ],
    'minzoom': 1,
    'maxzoom': 22,
    'paint': {
      'circle-radius': [
        'case',
        ['boolean', ['feature-state', 'active'], false],
        6,
        ['boolean', ['feature-state', 'hover'], false],
        6,
        5,
      ],
      'circle-color': colors.CIRCLE_FILL,
      'circle-stroke-color': [
        'case',
        ['boolean', ['feature-state', 'active'], false],
        'black',
        ['boolean', ['feature-state', 'hover'], false],
        colors.HOVER,
        colors.CIRCLE_STROKE,
      ],
      'circle-stroke-width': 3,
    },
    'layout': {
      visibility: 'none',
    },
  } as CircleLayerSpecification;
}

export function Add(map: Map): { id: string; layersIds: string[] } {
  map.addSource(sources.POINT_ID, sources.POINT);
  map.addLayer(layers.CIRCLE);
  return { id: ID, layersIds: [layers.CIRCLE.id] };
}

const INTERACTIVE_LAYERS_IDS: string[] = [layers.CIRCLE.id];
const HIGHLIGHT_ON_HOVER_LAYERS: LayerSpecification[] = [layers.CIRCLE];
const HIGHLIGHT_ON_CLICK_LAYERS: LayerSpecification[] = [layers.CIRCLE];

export { HIGHLIGHT_ON_CLICK_LAYERS, HIGHLIGHT_ON_HOVER_LAYERS, INTERACTIVE_LAYERS_IDS };
