import {
  CircleLayerSpecification,
  FillLayerSpecification,
  LayerSpecification,
  LineLayerSpecification,
  Map,
  SymbolLayerSpecification,
  VectorSourceSpecification,
} from 'mapbox-gl';
import { Consts } from '../global/consts';

export const ID = 'protecao_defesa_civil.area_risco_hidrologico';

namespace sources {
  export const POLIGONOS_ID = 'area_risco_hidrologico_poligonos';
  export const CENTROID_ID = 'area_risco_hidrologico_centroid';
  export const POLIGONOS_VECTORSOURCELAYER = 'protecao_defesa_civil.v_area_risco_hidrologico';
  export const CENTROID_VECTORSOURCELAYER = 'protecao_defesa_civil.v_area_risco_hidrologico_centroid';

  export const POLIGONOS_PERTO = {
    type: 'vector',
    tiles: [`${Consts.VECTOR_TILES_SERVER_URL}/${sources.POLIGONOS_VECTORSOURCELAYER}/{z}/{x}/{y}.pbf`],
    promoteId: 'id',
  } as VectorSourceSpecification;

  export const POLIGONOS_LONGE = {
    type: 'vector',
    tiles: [`${Consts.VECTOR_TILES_SERVER_URL}/${sources.CENTROID_VECTORSOURCELAYER}/{z}/{x}/{y}.pbf`],
    promoteId: 'id',
  } as VectorSourceSpecification;
}

namespace colors {
  export const HOVER = '#3B82F6';
  export const LABEL = '#0C1933';

  export const R1_OUTLINE = '#C0C6C8';
  export const R1_FILL = '#F0F7FA';

  export const R2_OUTLINE = '#8C9B97';
  export const R2_FILL = '#AFC2BD';

  export const R3_OUTLINE = '#465D8E';
  export const R3_FILL = '#5774B1';

  export const R4_OUTLINE = '#3266CC';
  export const R4_FILL = '#3E7FFF';
}

namespace layers {
  const ZOOM_POLIGONOS_THRESHOLD = 13;

  export const POLIGONOS_PERTO = {
    'id': 'poligonos_perto_area_risco_hidrologico-layer',
    'source': sources.POLIGONOS_ID,
    'source-layer': sources.POLIGONOS_VECTORSOURCELAYER,
    'type': 'line',
    'filter': ['!=', ['get', 'tx_grau_risco_hidrologico'], 'AREA ENCERRADA'],
    'minzoom': ZOOM_POLIGONOS_THRESHOLD,
    'paint': {
      'line-color': [
        'case',
        ['boolean', ['feature-state', 'active'], false],
        'transparent',
        ['boolean', ['feature-state', 'hover'], false],
        'transparent',
        ['==', ['get', 'tx_grau_risco_hidrologico'], 'R1'],
        colors.R1_OUTLINE,
        ['==', ['get', 'tx_grau_risco_hidrologico'], 'R2'],
        colors.R2_OUTLINE,
        ['==', ['get', 'tx_grau_risco_hidrologico'], 'R3'],
        colors.R3_OUTLINE,
        ['==', ['get', 'tx_grau_risco_hidrologico'], 'R4'],
        colors.R4_OUTLINE,
        'transparent',
      ],
      'line-width': 3,
    },
    'layout': {
      visibility: 'none',
    },
  } as LineLayerSpecification;

  export const POLIGONOS_PERTO_FILL = {
    'id': 'poligonos_perto_area_risco_hidrologico-fill-layer',
    'source': sources.POLIGONOS_ID,
    'source-layer': sources.POLIGONOS_VECTORSOURCELAYER,
    'type': 'fill',
    'filter': ['!=', ['get', 'tx_grau_risco_hidrologico'], 'AREA ENCERRADA'],
    'minzoom': ZOOM_POLIGONOS_THRESHOLD,
    'paint': {
      'fill-color': [
        'case',
        ['boolean', ['feature-state', 'active'], false],
        'transparent',
        ['boolean', ['feature-state', 'hover'], false],
        'transparent',
        ['==', ['get', 'tx_grau_risco_hidrologico'], 'R1'],
        colors.R1_FILL,
        ['==', ['get', 'tx_grau_risco_hidrologico'], 'R2'],
        colors.R2_FILL,
        ['==', ['get', 'tx_grau_risco_hidrologico'], 'R3'],
        colors.R3_FILL,
        ['==', ['get', 'tx_grau_risco_hidrologico'], 'R4'],
        colors.R4_FILL,
        'transparent',
      ],
      'fill-opacity': 0.4,
      'fill-outline-color': 'black',
    },
    'layout': {
      visibility: 'none',
    },
  } as FillLayerSpecification;

  export const POLIGONOS_LONGE = {
    'id': 'poligonos_longe_area_risco_hidrologico-layer',
    'source': sources.CENTROID_ID,
    'source-layer': sources.CENTROID_VECTORSOURCELAYER,
    'type': 'symbol',
    'filter': ['!=', ['get', 'tx_grau_risco_hidrologico'], 'AREA ENCERRADA'],
    'minzoom': 0,
    'maxzoom': ZOOM_POLIGONOS_THRESHOLD,
    'layout': {
      'text-font': ['Arial Unicode MS Bold'],
      'text-field': '●',
      'text-size': [
        'case',
        ['==', ['get', 'tx_grau_risco_hidrologico'], 'R1'],
        12,
        ['==', ['get', 'tx_grau_risco_hidrologico'], 'R2'],
        19,
        ['==', ['get', 'tx_grau_risco_hidrologico'], 'R3'],
        30,
        ['==', ['get', 'tx_grau_risco_hidrologico'], 'R4'],
        48,
        12,
      ],
      'text-anchor': 'center',
      'text-offset': [0, 0],
      'text-allow-overlap': true,
      'visibility': 'none',
    },
    'paint': {
      'text-color': [
        'case',
        ['boolean', ['feature-state', 'active'], false],
        'transparent',
        ['boolean', ['feature-state', 'hover'], false],
        colors.HOVER,
        ['==', ['get', 'tx_grau_risco_hidrologico'], 'R1'],
        colors.R1_OUTLINE,
        ['==', ['get', 'tx_grau_risco_hidrologico'], 'R2'],
        colors.R2_OUTLINE,
        ['==', ['get', 'tx_grau_risco_hidrologico'], 'R3'],
        colors.R3_OUTLINE,
        ['==', ['get', 'tx_grau_risco_hidrologico'], 'R4'],
        colors.R4_OUTLINE,
        'transparent',
      ],
      'text-halo-color': 'white',
      'text-halo-width': 3,
    },
  } as SymbolLayerSpecification;

  export const POLIGONOS_PERTO_LABEL = {
    'id': 'label_perto_poligonos_area_risco_hidrologico-layer',
    'source': sources.CENTROID_ID,
    'source-layer': sources.CENTROID_VECTORSOURCELAYER,
    'type': 'symbol',
    'filter': ['!=', ['get', 'tx_grau_risco_hidrologico'], 'AREA ENCERRADA'],
    'minzoom': ZOOM_POLIGONOS_THRESHOLD,
    'layout': {
      'text-font': ['Ubuntu Regular'],
      'text-field': ['concat', ['get', 'sg_area_risco_hidrologico'], '\n', ['get', 'nm_area_risco_hidrologico']],
      'text-size': 11,
      'text-letter-spacing': 0.05,
      'text-anchor': 'center',
      'text-offset': [0, 0],
      'text-allow-overlap': false,
      'visibility': 'none',
    },
    'paint': {
      'text-color': [
        'case',
        ['boolean', ['feature-state', 'active'], false],
        'black',
        ['boolean', ['feature-state', 'hover'], false],
        colors.HOVER,
        colors.LABEL,
      ],
      'text-halo-color': 'white',
      'text-halo-width': 2,
    },
  } as SymbolLayerSpecification;

  export const POLIGONOS_LONGE_LABEL = {
    'id': 'label_longe_poligonos_area_risco_hidrologico-layer',
    'source': sources.CENTROID_ID,
    'source-layer': sources.CENTROID_VECTORSOURCELAYER,
    'type': 'symbol',
    'filter': ['!=', ['get', 'tx_grau_risco_hidrologico'], 'AREA ENCERRADA'],
    'minzoom': 0,
    'maxzoom': ZOOM_POLIGONOS_THRESHOLD,
    'layout': {
      'text-font': ['Ubuntu Regular'],
      'text-field': ['get', 'sg_area_risco_hidrologico'],
      'text-size': 11,
      'text-letter-spacing': 0.05,
      'text-anchor': 'bottom',
      'text-offset': [0, -0.3],
      'text-allow-overlap': false,
      'visibility': 'none',
    },
    'paint': {
      'text-color': [
        'case',
        ['boolean', ['feature-state', 'active'], false],
        'black',
        ['boolean', ['feature-state', 'hover'], false],
        colors.HOVER,
        colors.LABEL,
      ],
      'text-halo-color': 'white',
      'text-halo-width': 2,
    },
  } as SymbolLayerSpecification;

  export const GAMBI = {
    // Gambiarra estranha: com o layer abaixo, o hover do label funciona. Sem,
    // o hover do label não funciona. Repare que o layer abaixo não tem nem
    // circle-radius. Mas, se for removido, o hover do label não funciona.
    'id': 'gambi_area_risco_hidrologico-layer',
    'source': sources.CENTROID_ID,
    'source-layer': sources.CENTROID_VECTORSOURCELAYER,
    'type': 'circle',
    'filter': ['!=', ['get', 'tx_grau_risco_hidrologico'], 'AREA ENCERRADA'],
    'layout': {
      visibility: 'none',
    },
    'paint': {
      'circle-radius': 0,
    },
  } as CircleLayerSpecification;
}

namespace active {
  export const POLIGONOS_PERTO = {
    ...layers.POLIGONOS_PERTO,
    id: 'poligonos_perto_area_risco_hidrologico-layer-active',
    layout: {
      visibility: 'none',
    },
    paint: {
      'line-color': [
        'case',
        ['boolean', ['feature-state', 'active'], false],
        'transparent',
        ['boolean', ['feature-state', 'hover'], false],
        colors.HOVER,
        'transparent',
      ],
      'line-width': 3,
    },
  } as LineLayerSpecification;
}

export function Add(map: Map): { id: string; layersIds: string[] } {
  map.addSource(sources.POLIGONOS_ID, sources.POLIGONOS_PERTO);
  map.addSource(sources.CENTROID_ID, sources.POLIGONOS_LONGE);

  const LAYERS_TO_ADD = [
    layers.POLIGONOS_LONGE,
    layers.POLIGONOS_PERTO,
    layers.POLIGONOS_PERTO_FILL,
    layers.POLIGONOS_PERTO_LABEL,
    layers.POLIGONOS_LONGE_LABEL,
    layers.GAMBI,
    active.POLIGONOS_PERTO,
  ];
  LAYERS_TO_ADD.map(layer => map.addLayer(layer));

  return {
    id: ID,
    layersIds: LAYERS_TO_ADD.map(layer => layer.id),
  };
}

const INTERACTIVE_LAYERS_IDS: string[] = [
  layers.POLIGONOS_PERTO.id,
  layers.POLIGONOS_LONGE.id,
  layers.POLIGONOS_PERTO_LABEL.id,
  layers.POLIGONOS_LONGE_LABEL.id,
];
const HIGHLIGHT_ON_HOVER_LAYERS: LayerSpecification[] = [
  layers.POLIGONOS_PERTO,
  layers.POLIGONOS_LONGE,
  layers.POLIGONOS_PERTO_LABEL,
  layers.POLIGONOS_LONGE_LABEL,
  active.POLIGONOS_PERTO,
];
const HIGHLIGHT_ON_CLICK_LAYERS: LayerSpecification[] = [
  layers.POLIGONOS_PERTO,
  layers.POLIGONOS_LONGE,
  layers.POLIGONOS_PERTO_LABEL,
  layers.POLIGONOS_LONGE_LABEL,
];

export const BBOX_FEATURE_URL = (() => {
  const url = new URL(
    `${Consts.GEOJSON_FEATURES_SERVER_URL}/collections/protecao_defesa_civil.v_area_risco_hidrologico_bbox/items.json`,
  );
  url.searchParams.set('limit', '4000');
  return url.toString();
})();

export { HIGHLIGHT_ON_CLICK_LAYERS, HIGHLIGHT_ON_HOVER_LAYERS, INTERACTIVE_LAYERS_IDS };
