import {
  Map,
  LayerSpecification,
  LineLayerSpecification,
  VectorSourceSpecification,
  SymbolLayerSpecification,
} from 'mapbox-gl';
import { Consts } from '../global/consts';

export const ID = 'meio_fisico_hidrografia.hidrografia';

namespace sources {
  export const LINES_ID = 'hidrografia-source';
  export const LINES_VECTORSOURCELAYER = 'meio_fisico_hidrografia.hidrografia_2024_08_14';
  export const DISSOLVED_ID = 'hidrografia_dissolved-source';
  export const DISSSOLVED_VECTORSOURCELAYER = 'meio_fisico_hidrografia.v_hidrografia_dissolved_2024_08_14';

  export const LINES = {
    type: 'vector',
    tiles: [`${Consts.VECTOR_TILES_SERVER_URL}/${LINES_VECTORSOURCELAYER}/{z}/{x}/{y}.pbf`],
    promoteId: 'hl_nome',
  } as VectorSourceSpecification;
  export const DISSOLVED = {
    type: 'vector',
    tiles: [`${Consts.VECTOR_TILES_SERVER_URL}/${DISSSOLVED_VECTORSOURCELAYER}/{z}/{x}/{y}.pbf`],
    promoteId: 'id',
  } as VectorSourceSpecification;
}

namespace colors {
  export const HOVER = '#3B82F6';
  export const ACTIVE = '#2563EB';
  export const LINE = '#B0BEC5';
  export const LINE_LONGE = '#CAD2D3';
  export const LABEL = '#607D8B';
}

namespace layers {
  export const NAMELESS_LINE = {
    'id': 'hidrografia_sem_nome-layer',
    'source': sources.LINES_ID,
    'source-layer': sources.LINES_VECTORSOURCELAYER,
    'type': 'line',
    'paint': {
      'line-color': colors.LINE_LONGE,
      'line-width': 1,
    },
    'minzoom': 11,
    'maxzoom': 24,
    'layout': {
      visibility: 'none',
    },
  } as LineLayerSpecification;

  export const DISSOLVED_LINE = {
    'id': 'hidrografia_dissolved-layer',
    'source': sources.DISSOLVED_ID,
    'source-layer': sources.DISSSOLVED_VECTORSOURCELAYER,
    'type': 'line',
    'paint': {
      'line-color': ['case', ['boolean', ['feature-state', 'hover'], false], colors.HOVER, colors.LINE],
      'line-width': 2,
    },
    'minzoom': 11,
    'maxzoom': 24,
    'layout': {
      visibility: 'none',
    },
  } as LineLayerSpecification;

  export const DISSOLVED_PRINCIPAL_LINE = {
    'id': 'hidrografia_principal-layer',
    'source': sources.DISSOLVED_ID,
    'source-layer': sources.DISSSOLVED_VECTORSOURCELAYER,
    'type': 'line',
    'paint': {
      'line-color': colors.LINE,
      'line-width': 3,
    },
    'filter': [
      'any',
      ['==', 'hl_nome', 'Rio Aricanduva'],
      ['==', 'hl_nome', 'Rio Pinheiros'],
      ['==', 'hl_nome', 'Rio Tamanduateí'],
      ['==', 'hl_nome', 'Rio Tietê'],
    ],
    'layout': {
      visibility: 'none',
    },
  } as LineLayerSpecification;

  export const LABEL = {
    'id': 'hidrografia-perto-label',
    'source': sources.DISSOLVED_ID,
    'source-layer': sources.DISSSOLVED_VECTORSOURCELAYER,
    'type': 'symbol',
    'layout': {
      'symbol-placement': 'line',
      'text-font': ['Ubuntu Regular'],
      'text-field': ['get', 'hl_nome'],
      'text-size': 14,
      'text-allow-overlap': true,
      'visibility': 'none',
    },
    'paint': {
      'text-color': [
        'case',
        ['boolean', ['feature-state', 'active'], false],
        colors.ACTIVE,
        ['boolean', ['feature-state', 'hover'], false],
        colors.HOVER,
        colors.LABEL,
      ],
      'text-halo-width': 2,
      'text-halo-color': 'white',
    },
    'minzoom': 11,
    'maxzoom': 24,
  } as SymbolLayerSpecification;
}

namespace active {
  export const TRECHOS = {
    'id': 'hidrografia_trechos-layer',
    'source': sources.LINES_ID,
    'source-layer': sources.LINES_VECTORSOURCELAYER,
    'type': 'line',
    'paint': {
      'line-color': [
        'case',
        ['boolean', ['feature-state', 'active'], false],
        [
          'match',
          ['get', 'hl_tpcurso'],
          'Trecho canalizado subterrâneo',
          '#EA7148',
          'Trecho canalizado a céu aberto',
          '#FF014F',
          'Trecho em estado natural',
          '#8446AB',
          'Trecho em lago ou reservatório',
          '#9BAB46',
          'red',
        ],
        'transparent',
      ],
      'line-width': [
        'case',
        ['boolean', ['feature-state', 'active'], false],
        5,
        ['boolean', ['feature-state', 'hover'], false],
        5,
        5,
      ],
    },
    'minzoom': 11,
    'maxzoom': 24,
    'layout': {
      visibility: 'visible',
    },
  } as LineLayerSpecification;
}

export function Add(map: Map): { id: string; layersIds: string[] } {
  map.addSource(sources.LINES_ID, sources.LINES);
  map.addSource(sources.DISSOLVED_ID, sources.DISSOLVED);

  map.addLayer(layers.NAMELESS_LINE);
  map.addLayer(layers.DISSOLVED_LINE);
  map.addLayer(layers.DISSOLVED_PRINCIPAL_LINE);
  map.addLayer(active.TRECHOS);
  map.addLayer(layers.LABEL);
  return {
    id: ID,
    layersIds: [
      layers.NAMELESS_LINE.id,
      layers.DISSOLVED_LINE.id,
      layers.DISSOLVED_PRINCIPAL_LINE.id,
      active.TRECHOS.id,
      layers.LABEL.id,
    ],
  };
}

export const BBOX_FEATURE_URL = (() => {
  const url = new URL(
    `${Consts.GEOJSON_FEATURES_SERVER_URL}/collections/meio_fisico_hidrografia.v_hidrografia_bbox_2024_08_14/items.json`,
  );
  url.searchParams.set('limit', '400');
  return url.toString();
})();

const INTERACTIVE_LAYERS_IDS: string[] = [layers.DISSOLVED_LINE.id];
const HIGHLIGHT_ON_HOVER_LAYERS: LayerSpecification[] = [layers.DISSOLVED_LINE, layers.LABEL];
const HIGHLIGHT_ON_CLICK_LAYERS: LayerSpecification[] = [active.TRECHOS, layers.LABEL];

export { INTERACTIVE_LAYERS_IDS, HIGHLIGHT_ON_HOVER_LAYERS, HIGHLIGHT_ON_CLICK_LAYERS };
