namespace Publishers {
  let _listeners = [];

  export type Visibility = 'visible' | 'none';

  export function Subscribe(listener: Function): Function {
    _listeners.push(listener);
    return listener;
  }

  export function Unsubscribe(listener: Function): void {
    _listeners = _listeners.filter(l => l !== listener);
  }

  export function Dispatch(id: string, visibility: Visibility): void {
    _listeners.forEach(l => l(id, visibility));
  }
}

export { Publishers };
