import { GeoJSONFeature, Layer, MapMouseEvent } from 'mapbox-gl';
import * as KeyEvents from '../domains/key-events';
import { Layers } from '../layers/_layers';
import * as FeatureState from './feature-state';

/**
 * ===============================================================================
 *                                TYPES/INTERFACES
 * ===============================================================================
 */

interface IActiveFeature {
  layerID: string;
  feature: GeoJSON.Feature;
}

/**
 * ===============================================================================
 *                              NAMESPACE PUBLISHERS
 * ===============================================================================
 */

namespace Publishers {
  let _listeners = [];

  export function Subscribe(listener: Function): Function {
    _listeners.push(listener);
    return listener;
  }

  export function Unsubscribe(listener: Function): void {
    _listeners = _listeners.filter(l => l !== listener);
  }

  export function Dispatch(activeFeature: IActiveFeature): void {
    _listeners.forEach(l => l(activeFeature));
  }
}

/**
 * ===============================================================================
 *                              NAMESPACE CONTROLLERS
 * ===============================================================================
 */

namespace Controllers {
  function logFeature(feature: GeoJSONFeature): void {
    console.table({
      source: feature.source,
      layer: feature.layer.id,
      sourceLayer: feature.sourceLayer,
      ...feature.properties,
    });
  }

  export function OnMouseClick(interactiveLayersIds: string[], highlightOnClickLayers: Layer[]): Function {
    return (e: MapMouseEvent & { features: GeoJSONFeature[] }) => {
      const feature = e.features[0];
      if (!interactiveLayersIds.includes(feature.layer.id)) return;
      const featureState = 'active';
      FeatureState.Controllers.Set(feature.id as string, highlightOnClickLayers, featureState);
      const id = Layers.WhoseIsThis(feature.layer.id);
      Publishers.Dispatch({ layerID: id, feature });
      logFeature(feature);
    };
  }
}

/**
 * ===============================================================================
 *                                   SUBSCRIBERS
 * ===============================================================================
 */

KeyEvents.Publishers.SubscribeEsc(() => {
  Publishers.Dispatch({ layerID: '', feature: null });
  FeatureState.Controllers.Clear('active');
});

export { Controllers, IActiveFeature, Publishers };
