import { Map, LineLayerSpecification, SymbolLayerSpecification, VectorSourceSpecification } from 'mapbox-gl';
import { Consts } from '../global/consts';

export const ID = 'meio_fisico_topografia.curvas_nivel';


namespace sources {
  export const SOURCE_CURVA_NIVEL_1M_ID = 'curvas-nivel-1m-source';
  export const SOURCE_CURVA_NIVEL_5M_ID = 'curvas-nivel-5m-source';
  export const SOURCE_CURVA_NIVEL_25M_ID = 'curvas-nivel-25m-source';
  export const CURVA_NIVEL_1M_VECTORSOURCELAYER = 'meio_fisico_topografia.curvas_nivel';
  export const CURVA_NIVEL_5M_VECTORSOURCELAYER = 'meio_fisico_topografia.v_curvas_nivel_5m';
  export const CURVA_NIVEL_25M_VECTORSOURCELAYER = 'meio_fisico_topografia.v_curvas_nivel_25m';
  
  export const SOURCE_1M = {
    type: 'vector',
    tiles: [`${Consts.VECTOR_TILES_SERVER_URL}/${CURVA_NIVEL_1M_VECTORSOURCELAYER}/{z}/{x}/{y}.pbf`],
  } as VectorSourceSpecification;
  
  export const SOURCE_5M = {
    type: 'vector',
    tiles: [`${Consts.VECTOR_TILES_SERVER_URL}/${CURVA_NIVEL_5M_VECTORSOURCELAYER}/{z}/{x}/{y}.pbf`],
  } as VectorSourceSpecification;
  
  export const SOURCE_25M = {
    type: 'vector',
    tiles: [`${Consts.VECTOR_TILES_SERVER_URL}/${CURVA_NIVEL_25M_VECTORSOURCELAYER}/{z}/{x}/{y}.pbf`],
  } as VectorSourceSpecification;
}

namespace layers {
  export const LAYER_CURVAS_NIVEL_MESTRA_LONGE_ID = 'curvas-nivel-mestra-longe-layer';
  export const LAYER_CURVAS_NIVEL_INTERMEDIARIA_LONGE_ID = 'curvas-nivel-intermediaria-longe-layer';
  export const LAYER_CURVAS_NIVEL_LABEL_LONGE_ID = 'curvas-nivel-label-longe-layer';
  export const LAYER_CURVAS_NIVEL_MESTRA_PERTO_ID = 'curvas-nivel-mestra-perto-layer';
  export const LAYER_CURVAS_NIVEL_INTERMEDIARIA_PERTO_ID = 'curvas-nivel-intermediaria-perto-layer';
  export const LAYER_CURVAS_NIVEL_LABEL_PERTO_ID = 'curvas-nivel-label-perto-layer';
  export const layerMestraLonge = {
    'id': LAYER_CURVAS_NIVEL_MESTRA_LONGE_ID,
    'source': sources.SOURCE_CURVA_NIVEL_25M_ID,
    'source-layer': sources.CURVA_NIVEL_25M_VECTORSOURCELAYER,
    'type': 'line',
    'minzoom': 0,
    'maxzoom': 16,
    'paint': {
      'line-color': '#BDBDBD',
      'line-opacity': [
        'interpolate',
        ['linear'],
        ['zoom'],
        // zoom 10 (ou menor): zoom da rmsp
        10,
        0.1,
        // zoom 13 (ou maior): é o minzoom do curvas-nivel-intermediaria-longe-layer
        13,
        1,
      ],
      'line-width': 1,
    },
    'layout': {
      visibility: 'none',
    },
  } as LineLayerSpecification;
  export const layerIntermediariaLonge = {
    'id': LAYER_CURVAS_NIVEL_INTERMEDIARIA_LONGE_ID,
    'source': sources.SOURCE_CURVA_NIVEL_5M_ID,
    'source-layer': sources.CURVA_NIVEL_5M_VECTORSOURCELAYER,
    'type': 'line',
    'minzoom': 13,
    'maxzoom': 16,
    'paint': {
      'line-color': '#BDBDBD',
      'line-opacity': 0.6,
      'line-width': 1,
    },
    'layout': {
      visibility: 'none',
    },
  } as LineLayerSpecification;
  export const layerLabelLonge = {
    'id': LAYER_CURVAS_NIVEL_LABEL_LONGE_ID,
    'source': sources.SOURCE_CURVA_NIVEL_25M_ID,
    'source-layer': sources.CURVA_NIVEL_25M_VECTORSOURCELAYER,
    'type': 'symbol',
    'minzoom': 0,
    'maxzoom': 16,
    'layout': {
      'symbol-placement': 'line',
      'text-font': ['Ubuntu Regular'],
      'text-field': ['get', 'isovalor'],
      'text-allow-overlap': true,
      'text-size': 10,
      'visibility': 'none',
    },
    'paint': {
      'text-color': '#424242',
      'text-halo-color': '#FFFFFF',
      'text-halo-width': 1,
    },
  } as SymbolLayerSpecification;
  export const layerMestrePerto = {
    'id': LAYER_CURVAS_NIVEL_MESTRA_PERTO_ID,
    'source': sources.SOURCE_CURVA_NIVEL_5M_ID,
    'source-layer': sources.CURVA_NIVEL_5M_VECTORSOURCELAYER,
    'type': 'line',
    'minzoom': 16,
    'maxzoom': 24,
    'paint': {
      'line-color': '#BDBDBD',
      'line-opacity': 1,
      'line-width': 1,
    },
    'layout': {
      visibility: 'none',
    },
  } as LineLayerSpecification;
  export const layerIntermediariaPerto = {
    'id': LAYER_CURVAS_NIVEL_INTERMEDIARIA_PERTO_ID,
    'source': sources.SOURCE_CURVA_NIVEL_1M_ID,
    'source-layer': sources.CURVA_NIVEL_1M_VECTORSOURCELAYER,
    'type': 'line',
    'minzoom': 16,
    'maxzoom': 24,
    'paint': {
      'line-color': '#BDBDBD',
      'line-opacity': 0.6,
      'line-width': 1,
    },
    'layout': {
      visibility: 'none',
    },
  } as LineLayerSpecification;
  export const layerLabelPerto = {
    'id': LAYER_CURVAS_NIVEL_LABEL_PERTO_ID,
    'source': sources.SOURCE_CURVA_NIVEL_5M_ID,
    'source-layer': sources.CURVA_NIVEL_5M_VECTORSOURCELAYER,
    'type': 'symbol',
    'minzoom': 16,
    'maxzoom': 24,
    'layout': {
      'symbol-placement': 'line',
      'text-font': ['Ubuntu Regular'],
      'text-field': ['get', 'isovalor'],
      'text-allow-overlap': true,
      'text-size': 10,
      'visibility': 'none',
    },
    'paint': {
      'text-color': '#424242',
      'text-halo-color': '#FFFFFF',
      'text-halo-width': 1,
    },
  } as SymbolLayerSpecification;
}

export function Add(map: Map): { id: string; layersIds: string[] } {
  map.addSource(sources.SOURCE_CURVA_NIVEL_1M_ID, sources.SOURCE_1M);
  map.addSource(sources.SOURCE_CURVA_NIVEL_5M_ID, sources.SOURCE_5M);
  map.addSource(sources.SOURCE_CURVA_NIVEL_25M_ID, sources.SOURCE_25M);

  map.addLayer(layers.layerMestraLonge);
  map.addLayer(layers.layerIntermediariaLonge);
  map.addLayer(layers.layerLabelLonge);
  map.addLayer(layers.layerMestrePerto);
  map.addLayer(layers.layerIntermediariaPerto);
  map.addLayer(layers.layerLabelPerto);
  return {
    id: ID,
    layersIds: [
      layers.LAYER_CURVAS_NIVEL_MESTRA_LONGE_ID,
      layers.LAYER_CURVAS_NIVEL_INTERMEDIARIA_LONGE_ID,
      layers.LAYER_CURVAS_NIVEL_LABEL_LONGE_ID,
      layers.LAYER_CURVAS_NIVEL_MESTRA_PERTO_ID,
      layers.LAYER_CURVAS_NIVEL_INTERMEDIARIA_PERTO_ID,
      layers.LAYER_CURVAS_NIVEL_LABEL_PERTO_ID,
    ],
  };
}
