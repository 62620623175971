import { FeatureSelector, Layer } from 'mapbox-gl';
import { Libs } from '../libs/_libs';

/**
 * ===============================================================================
 *                                NAMESPACE ENTITIES
 * ===============================================================================
 */

namespace Entities {
  let _featureSelectorMap = new Map<string, FeatureSelector[]>();

  export function Push(featureState: string, featureSelectors: FeatureSelector[]): void {
    _featureSelectorMap.set(featureState, featureSelectors);
  }

  export function Get(featureState: string): FeatureSelector[] {
    return _featureSelectorMap.get(featureState);
  }

  export function Clear(featureState: string): void {
    _featureSelectorMap.set(featureState, []);
  }
}

/**
 * ===============================================================================
 *                              NAMESPACE CONTROLLERS
 * ===============================================================================
 */

namespace Controllers {
  export function Set(featureId: string, highlightLayers: Layer[], featureState: string): void {
    Controllers.Clear(featureState);
    Entities.Clear(featureState);
    const featureSelectors: FeatureSelector[] = [];
    highlightLayers.forEach(item => {
      const featureSelector: FeatureSelector = {
        id: featureId,
        source: item['source'].toString(),
        sourceLayer: item['source-layer'],
      };
      Libs.Mapbox.Map.setFeatureState(featureSelector, { [featureState]: true });
      featureSelectors.push(featureSelector);
    });
    Entities.Push(featureState, featureSelectors);
  }

  export function Clear(featureState: string): void {
    const featureSelectors = Entities.Get(featureState);
    if (!featureSelectors) return;
    featureSelectors.forEach(item => Libs.Mapbox.Map.removeFeatureState(item, featureState));
  }
}

export { Controllers };
