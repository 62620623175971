import {
  Map,
  VectorSourceSpecification,
  LineLayerSpecification,
  SymbolLayerSpecification,
  LayerSpecification,
} from 'mapbox-gl';
import { Consts } from '../global/consts';

export const ID = 'cadernos_drenagem.obras_complementares_2024_03_21';

namespace sources {
  export const PONTOS_ID = 'obras_complementares_pontos_2024_03_01';
  export const LINHAS_ID = 'obras_complementares_linhas_2024_03_01';
  export const PONTOS_VECTORSOURCELAYER = 'cadernos_drenagem.obras_complementares_pontos_2024_03_21';
  export const LINHAS_VECTORSOURCELAYER = 'cadernos_drenagem.obras_complementares_linhas_2024_03_21';

  export const PONTOS = {
    type: 'vector',
    tiles: [`${Consts.VECTOR_TILES_SERVER_URL}/${PONTOS_VECTORSOURCELAYER}/{z}/{x}/{y}.pbf`],
    promoteId: 'codigo',
  } as VectorSourceSpecification;

  export const LINHAS = {
    type: 'vector',
    tiles: [`${Consts.VECTOR_TILES_SERVER_URL}/${sources.LINHAS_VECTORSOURCELAYER}/{z}/{x}/{y}.pbf`],
    promoteId: 'codigo',
  } as VectorSourceSpecification;
}

namespace colors {
  export const HOVER = '#3B82F6';
  export const ACTIVE = '#2563EB';
  export const DEFAULT = '#134E4A';
}

namespace layers {
  export const PONTOS = {
    'id': 'obras_complementares_pontos_2024_03_21-layer',
    'source': sources.PONTOS_ID,
    'source-layer': sources.PONTOS_VECTORSOURCELAYER,
    'type': 'symbol',
    'minzoom': 0,
    'maxzoom': 22,
    'layout': {
      'text-font': ['Arial Unicode MS Bold'],
      'text-field': '●',
      'text-size': 20,
      'text-anchor': 'center',
      'text-offset': [0, 0],
      'text-allow-overlap': true,
      'visibility': 'none',
    },
    'paint': {
      'text-color': colors.DEFAULT,
      'text-halo-color': 'white',
      'text-halo-width': 2,
    },
  } as SymbolLayerSpecification;

  export const LABEL_PONTOS = {
    'id': 'obras_complementares_pontos_2024_03_21-label-layer',
    'source': sources.PONTOS_ID,
    'source-layer': sources.PONTOS_VECTORSOURCELAYER,
    'type': 'symbol',
    'minzoom': 12,
    'maxzoom': 22,
    'layout': {
      'text-font': ['Ubuntu Bold'],
      'text-field': ['get', 'tipo'],
      'text-size': 12,
      'text-letter-spacing': 0.05,
      'text-anchor': 'bottom',
      'text-offset': [0, -1],
      'text-transform': 'uppercase',
      'visibility': 'none',
    },
    'paint': {
      'text-color': colors.DEFAULT,
      'text-halo-width': 2,
      'text-halo-color': 'white',
    },
  } as SymbolLayerSpecification;

  export const LINHAS = {
    'id': 'obras_complementares_linhas_2024_03_21-layer',
    'source': sources.LINHAS_ID,
    'source-layer': sources.LINHAS_VECTORSOURCELAYER,
    'type': 'line',
    'paint': {
      'line-color': colors.DEFAULT,
      'line-width': 9,
    },
    'minzoom': 12,
    'maxzoom': 22,
    'layout': {
      visibility: 'none',
    },
  } as LineLayerSpecification;

  export const LABEL_LINHAS = {
    'id': 'obras_complementares_linhas_2024_03_21-label-layer',
    'source': sources.LINHAS_ID,
    'source-layer': sources.LINHAS_VECTORSOURCELAYER,
    'type': 'symbol',
    'layout': {
      'symbol-placement': 'line',
      'text-font': ['Ubuntu Bold'],
      'text-field': ['get', 'tipo'],
      'text-size': 12,
      'text-allow-overlap': false,
      'text-transform': 'uppercase',
      'visibility': 'none',
    },
    'paint': {
      'text-color': colors.DEFAULT,
      'text-halo-width': 2,
      'text-halo-color': 'white',
    },
    'minzoom': 12,
    'maxzoom': 22,
  } as SymbolLayerSpecification;
}

namespace active {
  export const PONTOS = {
    ...layers.PONTOS,
    id: 'obras_complementares_pontos_2024_03_21_active-layer',
    paint: {
      'text-color': [
        'case',
        ['boolean', ['feature-state', 'active'], false],
        colors.ACTIVE,
        ['boolean', ['feature-state', 'hover'], false],
        colors.HOVER,
        'transparent',
      ],
      'text-halo-width': [
        'case',
        ['boolean', ['feature-state', 'active'], false],
        2,
        ['boolean', ['feature-state', 'hover'], false],
        2,
        0,
      ],
      'text-halo-color': [
        'case',
        ['boolean', ['feature-state', 'active'], false],
        'white',
        ['boolean', ['feature-state', 'hover'], false],
        'white',
        'transparent',
      ],
    },
  } as SymbolLayerSpecification;

  export const LABEL_PONTOS = {
    ...layers.LABEL_PONTOS,
    id: 'obras_complementares_pontos_2024_03_21-label_active-layer',
    paint: {
      'text-color': [
        'case',
        ['boolean', ['feature-state', 'active'], false],
        colors.ACTIVE,
        ['boolean', ['feature-state', 'hover'], false],
        colors.HOVER,
        'transparent',
      ],
      'text-halo-width': [
        'case',
        ['boolean', ['feature-state', 'active'], false],
        2,
        ['boolean', ['feature-state', 'hover'], false],
        2,
        0,
      ],
      'text-halo-color': [
        'case',
        ['boolean', ['feature-state', 'active'], false],
        'white',
        ['boolean', ['feature-state', 'hover'], false],
        'white',
        'transparent',
      ],
    },
  } as SymbolLayerSpecification;

  export const LINHAS = {
    ...layers.LINHAS,
    id: 'obras_complementares_linhas_2024_03_21_active-layer',
    paint: {
      'line-color': [
        'case',
        ['boolean', ['feature-state', 'active'], false],
        colors.ACTIVE,
        ['boolean', ['feature-state', 'hover'], false],
        colors.HOVER,
        'transparent',
      ],
      'line-width': 9,
    },
  } as LineLayerSpecification;

  export const LABEL_LINHAS = {
    ...layers.LABEL_LINHAS,
    id: 'obras_complementares_linhas_2024_03_21-label_active-layer',
    paint: {
      'text-color': [
        'case',
        ['boolean', ['feature-state', 'active'], false],
        colors.ACTIVE,
        ['boolean', ['feature-state', 'hover'], false],
        colors.HOVER,
        'transparent',
      ],
      'text-halo-width': [
        'case',
        ['boolean', ['feature-state', 'active'], false],
        2,
        ['boolean', ['feature-state', 'hover'], false],
        2,
        0,
      ],
      'text-halo-color': [
        'case',
        ['boolean', ['feature-state', 'active'], false],
        'white',
        ['boolean', ['feature-state', 'hover'], false],
        'white',
        'transparent',
      ],
    },
    minzoom: 12,
    maxzoom: 22,
  } as SymbolLayerSpecification;
}

export function Add(map: Map): { id: string; layersIds: string[] } {
  map.addSource(sources.PONTOS_ID, sources.PONTOS);
  map.addSource(sources.LINHAS_ID, sources.LINHAS);

  map.addLayer(layers.LINHAS);
  map.addLayer(layers.PONTOS);
  map.addLayer(layers.LABEL_PONTOS);
  map.addLayer(layers.LABEL_LINHAS);
  map.addLayer(active.LINHAS);
  map.addLayer(active.PONTOS);
  map.addLayer(active.LABEL_PONTOS);
  map.addLayer(active.LABEL_LINHAS);

  return {
    id: ID,
    layersIds: [
      layers.PONTOS.id,
      layers.LABEL_PONTOS.id,
      layers.LINHAS.id,
      layers.LABEL_LINHAS.id,
      active.LINHAS.id,
      active.PONTOS.id,
      active.LABEL_PONTOS.id,
      active.LABEL_LINHAS.id,
    ],
  };
}

const INTERACTIVE_LAYERS_IDS: string[] = [
  layers.PONTOS.id,
  layers.LINHAS.id,
  layers.LABEL_PONTOS.id,
  layers.LABEL_LINHAS.id,
  active.LINHAS.id,
  active.PONTOS.id,
  active.LABEL_PONTOS.id,
  active.LABEL_LINHAS.id,
];
const HIGHLIGHT_ON_HOVER_LAYERS: LayerSpecification[] = [
  active.LINHAS,
  active.PONTOS,
  active.LABEL_PONTOS,
  active.LABEL_LINHAS,
];
const HIGHLIGHT_ON_CLICK_LAYERS: LayerSpecification[] = [
  active.LINHAS,
  active.PONTOS,
  active.LABEL_PONTOS,
  active.LABEL_LINHAS,
];

export { INTERACTIVE_LAYERS_IDS, HIGHLIGHT_ON_HOVER_LAYERS, HIGHLIGHT_ON_CLICK_LAYERS };
