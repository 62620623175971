import {
  CircleLayerSpecification,
  LayerSpecification,
  LineLayerSpecification,
  Map,
  SymbolLayerSpecification,
  VectorSourceSpecification,
} from 'mapbox-gl';
import { FetchItem, OnGetFeatureFromLatLng } from '../fetcher/fetcher';
import { Consts } from '../global/consts';

export const ID = 'meio_fisico_hidrografia.bacias_hidrograficas';

namespace sources {
  export const POLYGON_ID = 'bacias_hidrograficas-source';
  export const CENTROID_ID = 'label_bacias_hidrograficas-source';
  export const POLYGON_VECTORSOURCELAYER = 'meio_fisico_hidrografia.bacias_hidrograficas_2024_06_26';
  export const CENTROID_VECTORSOURCELAYER = 'meio_fisico_hidrografia.v_bacias_hidrograficas_centroid_2024_06_26';

  export const POLYGON = {
    type: 'vector',
    tiles: [`${Consts.VECTOR_TILES_SERVER_URL}/${POLYGON_VECTORSOURCELAYER}/{z}/{x}/{y}.pbf`],
    promoteId: 'id',
  } as VectorSourceSpecification;

  export const CENTROID = {
    type: 'vector',
    tiles: [`${Consts.VECTOR_TILES_SERVER_URL}/${CENTROID_VECTORSOURCELAYER}/{z}/{x}/{y}.pbf`],
    promoteId: 'id',
  } as VectorSourceSpecification;
}

namespace colors {
  export const HOVER = '#3B82F6';
  export const LINE = '#607D8B';
}

namespace layers {
  export const POLYGON = {
    'id': 'bacias_hidrograficas-layer',
    'source': sources.POLYGON_ID,
    'source-layer': sources.POLYGON_VECTORSOURCELAYER,
    'type': 'line',
    'filter': ['!', ['in', 'Área de Contribuição', ['get', 'bacia_nome']]],
    'paint': {
      'line-color': [
        'case',
        ['boolean', ['feature-state', 'active'], false],
        'transparent',
        ['boolean', ['feature-state', 'hover'], false],
        colors.HOVER,
        colors.LINE,
      ],
      'line-width': ['interpolate', ['exponential', 2], ['zoom'], 0, 0.5, 10, 2, 24, 4],
    },
    'layout': {
      visibility: 'none',
    },
  } as LineLayerSpecification;

  export const GAMBI = {
    // Gambiarra estranha: com o layer abaixo, o hover do label funciona. Sem,
    // o hover do label não funciona. Repare que o layer abaixo não tem nem cor
    // nem circle-radius. Mas, se for removido, o hover do label não funciona.
    'id': 'gambi_circle_bacias_hidrograficas-layer',
    'source': sources.CENTROID_ID,
    'source-layer': sources.CENTROID_VECTORSOURCELAYER,
    'type': 'circle',
    'layout': {
      visibility: 'none',
    },
    'paint': {
      'circle-radius': 0,
    },
  } as CircleLayerSpecification;

  export const LABEL = {
    'id': 'label_bacias_hidrograficas-layer',
    'source': sources.CENTROID_ID,
    'source-layer': sources.CENTROID_VECTORSOURCELAYER,
    'type': 'symbol',
    'filter': ['!', ['in', 'Área de Contribuição', ['get', 'bacia_nome']]],
    'layout': {
      'text-font': ['Ubuntu Regular'],
      'text-field': ['concat', 'Bacia\n', ['get', 'bacia_nome']],
      'text-max-width': 8,
      'text-size': [
        'interpolate',
        ['linear'],
        ['zoom'],
        // zoom 10 (ou menor):
        10,
        12,
        // zoom 13 (ou maior):
        13,
        18,
      ],
      'text-anchor': 'center',
      'text-transform': 'uppercase',
      'visibility': 'none',
    },
    'paint': {
      'text-color': [
        'case',
        ['boolean', ['feature-state', 'active'], false],
        'black',
        ['boolean', ['feature-state', 'hover'], false],
        colors.HOVER,
        colors.LINE,
      ],
      'text-halo-width': 3,
      'text-halo-color': 'white',
    },
  } as SymbolLayerSpecification;
}

namespace active {
  export const POLYGON = {
    ...layers.POLYGON,
    id: 'bacias_hidrograficas-layer-active',
    paint: {
      'line-color': [
        'case',
        ['boolean', ['feature-state', 'active'], false],
        'transparent',
        ['boolean', ['feature-state', 'hover'], false],
        colors.HOVER,
        'transparent',
      ],
      'line-width': 3,
    },
  } as LineLayerSpecification;
}

export function Add(map: Map): { id: string; layersIds: string[] } {
  map.addSource(sources.POLYGON_ID, sources.POLYGON);
  map.addSource(sources.CENTROID_ID, sources.CENTROID);

  map.addLayer(layers.POLYGON);
  map.addLayer(layers.GAMBI);
  map.addLayer(layers.LABEL);
  map.addLayer(active.POLYGON);

  return {
    id: ID,
    layersIds: [layers.POLYGON.id, layers.GAMBI.id, layers.LABEL.id, active.POLYGON.id],
  };
}

namespace Fetcher {
  export async function BboxById(id: string): Promise<{ geojson: GeoJSON.Feature<GeoJSON.Geometry>; error: Error }> {
    const url = `${Consts.GEOJSON_FEATURES_SERVER_URL}/collections/meio_fisico_hidrografia.bacias_hidrograficas_2024_06_26/items.json`;
    return (await FetchItem(url.toString(), 'id', id)) as { geojson: GeoJSON.Feature<GeoJSON.Geometry>; error: Error };
  }

  export async function GetFeatureFromLatLng(
    lat: number,
    lng: number,
  ): Promise<{ geojson: GeoJSON.Feature; error: Error }> {
    const getFeature = await OnGetFeatureFromLatLng('get_bacia_from_latlng_2024_06_26');
    return await getFeature(lat, lng);
  }
}

export const BBOX_FEATURE_URL = (() => {
  const url = new URL(
    `${Consts.GEOJSON_FEATURES_SERVER_URL}/collections/meio_fisico_hidrografia.v_bacias_hidrograficas_bbox_2024_06_26/items.json`,
  );
  url.searchParams.set('limit', '400');
  return url.toString();
})();

const INTERACTIVE_LAYERS_IDS: string[] = [layers.LABEL.id];
const HIGHLIGHT_ON_HOVER_LAYERS: LayerSpecification[] = [layers.POLYGON, layers.LABEL, active.POLYGON];
const HIGHLIGHT_ON_CLICK_LAYERS: LayerSpecification[] = [layers.POLYGON, layers.LABEL];

export { Fetcher, HIGHLIGHT_ON_CLICK_LAYERS, HIGHLIGHT_ON_HOVER_LAYERS, INTERACTIVE_LAYERS_IDS };

