import { Map, LineLayerSpecification, VectorSourceSpecification } from 'mapbox-gl';
import { Consts } from '../global/consts';

export const ID = 'infraestrutura_urbana.galeria_executada_ramal';
export const MIN_ZOOM = 14;

namespace sources {
  export const LINE_ID = 'galeria_executada_ramal_2022_08_22';
  export const LINE_VECTORSOURCELAYER = 'infraestrutura_urbana.galeria_executada_ramal_2022_08_22';

  export const LINE = {
    type: 'vector',
    tiles: [`${Consts.VECTOR_TILES_SERVER_URL}/${LINE_VECTORSOURCELAYER}/{z}/{x}/{y}.pbf`],
  } as VectorSourceSpecification;
}

namespace layers {
  export const LINE = {
    'id': 'galeria_executada_ramal_2022_08_22-layer',
    'source': sources.LINE_ID,
    'source-layer': sources.LINE_VECTORSOURCELAYER,
    'minzoom': MIN_ZOOM,
    'maxzoom': 22,
    'type': 'line',
    'paint': {
      'line-color': '#1D4ED8',
    },
    'layout': {
      visibility: 'none',
    },
  } as LineLayerSpecification;
}

export function Add(map: Map): { id: string; layersIds: string[] } {
  map.addSource(sources.LINE_ID, sources.LINE);
  map.addLayer(layers.LINE);
  return { id: ID, layersIds: [layers.LINE.id] };
}
