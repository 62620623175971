namespace Entities {
  const _states = new Map();

  export function Clear() {
    _states.clear();
  }

  export function Set(id: string, state: boolean): void {
    _states.set(id, state);
  }

  export function Get(id: string): boolean {
    return _states.get(id);
  }

  export function GetAll(): Map<string, boolean> {
    return _states;
  }
}

export { Entities };
