import { Map, VectorSourceSpecification, LineLayerSpecification } from 'mapbox-gl';
import { Consts } from '../global/consts';

export const ID = 'limites_administrativos.rmsp';

namespace sources {
  export const POLYGON_ID = 'rmsp-source';
  export const POLYGON_VECTORSOURCELAYER = 'limites_administrativos.v_rmsp';
  export const POLYGON = {
    type: 'vector',
    tiles: [`${Consts.VECTOR_TILES_SERVER_URL}/${POLYGON_VECTORSOURCELAYER}/{z}/{x}/{y}.pbf`],
  } as VectorSourceSpecification;
}

namespace colors {
  export const DEFAULT = '#BDBDBD';
}

namespace layers {
  export const POLYGON_ID = 'rmsp-layer';
  export const POLYGON = {
    'id': POLYGON_ID,
    'source': sources.POLYGON_ID,
    'source-layer': sources.POLYGON_VECTORSOURCELAYER,
    'type': 'line',
    'paint': {
      'line-color': colors.DEFAULT,
      'line-width': 1,
    },
    'layout': {
      visibility: 'none',
    },
  } as LineLayerSpecification;
}

export function Add(map: Map): { id: string; layersIds: string[] } {
  map.addSource(sources.POLYGON_ID, sources.POLYGON);
  map.addLayer(layers.POLYGON);
  return { id: ID, layersIds: [layers.POLYGON_ID] };
}
